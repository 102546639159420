import { CacheStrategy, DEFAULT_SERVER_TIME_INTERVAL, DEFAULT_BROWSER_TIME_INTERVAL, DEFAULT_BROWSER_POOL_MAX_SIZE, DEFAULT_SERVER_POOL_MAX_SIZE } from '../enum/index';
import { isBrowser } from '../utils/utils';
export class TrackingManagerConfig {
    _batchIntervals;
    _poolMaxSize;
    _batchStrategy;
    constructor(param) {
        this.batchIntervals = param?.batchIntervals;
        this.poolMaxSize = param?.poolMaxSize;
        this._batchStrategy = this.getCacheStrategy(param?.cacheStrategy);
    }
    getCacheStrategy(cacheStrategy) {
        if (typeof cacheStrategy === 'number') {
            return cacheStrategy;
        }
        return (isBrowser() ? CacheStrategy.CONTINUOUS_CACHING : CacheStrategy.PERIODIC_CACHING);
    }
    get batchIntervals() {
        return this._batchIntervals;
    }
    set batchIntervals(v) {
        if (typeof v !== 'number' || v < 1 || v > 10800) {
            v = isBrowser() ? DEFAULT_BROWSER_TIME_INTERVAL : DEFAULT_SERVER_TIME_INTERVAL;
        }
        this._batchIntervals = v;
    }
    get poolMaxSize() {
        return this._poolMaxSize;
    }
    set poolMaxSize(v) {
        if (typeof v !== 'number' || v < 5) {
            v = isBrowser() ? DEFAULT_BROWSER_POOL_MAX_SIZE : DEFAULT_SERVER_POOL_MAX_SIZE;
        }
        this._poolMaxSize = v;
    }
    get cacheStrategy() {
        return this._batchStrategy;
    }
}
