import { ANONYMOUS_ID, CUSTOMER_ENV_ID_API_ACTIVATE, QT_API_ITEM, VARIATION_GROUP_ID_API_ITEM_ACTIVATE, VARIATION_ID_API_ITEM, VISITOR_ID_API_ITEM } from '../enum/index';
import { HitAbstract } from './HitAbstract';
export const ERROR_MESSAGE = 'variationGroupId and variationId are required';
export class Activate extends HitAbstract {
    _variationGroupId;
    _variationId;
    _flagKey;
    _flagValue;
    _flagDefaultValue;
    _flagMetadata;
    _visitorContext;
    constructor(param) {
        super({
            type: 'ACTIVATE',
            userIp: param.userIp,
            screenResolution: param.screenResolution,
            locale: param.locale,
            sessionNumber: param.sessionNumber,
            visitorId: param.visitorId,
            anonymousId: param.anonymousId
        });
        const { variationGroupId, variationId, flagKey, flagValue, flagDefaultValue, flagMetadata, visitorContext } = param;
        this.variationGroupId = variationGroupId;
        this.variationId = variationId;
        this.flagKey = flagKey;
        this.flagValue = flagValue;
        this.flagDefaultValue = flagDefaultValue;
        this.flagMetadata = flagMetadata;
        this.visitorContext = visitorContext;
    }
    get variationGroupId() {
        return this._variationGroupId;
    }
    set variationGroupId(v) {
        this._variationGroupId = v;
    }
    get variationId() {
        return this._variationId;
    }
    set variationId(v) {
        this._variationId = v;
    }
    get flagKey() {
        return this._flagKey;
    }
    set flagKey(v) {
        this._flagKey = v;
    }
    get flagValue() {
        return this._flagValue;
    }
    set flagValue(v) {
        this._flagValue = v;
    }
    get flagDefaultValue() {
        return this._flagDefaultValue;
    }
    set flagDefaultValue(v) {
        this._flagDefaultValue = v;
    }
    get flagMetadata() {
        return this._flagMetadata;
    }
    set flagMetadata(v) {
        this._flagMetadata = v;
    }
    get visitorContext() {
        return this._visitorContext;
    }
    set visitorContext(v) {
        this._visitorContext = v;
    }
    isReady(checkParent = true) {
        return !!((!checkParent || super.isReady()) && this.variationGroupId && this.variationId);
    }
    toApiKeys() {
        const apiKeys = {
            [VISITOR_ID_API_ITEM]: this.anonymousId || this.visitorId,
            [VARIATION_ID_API_ITEM]: this.variationId,
            [VARIATION_GROUP_ID_API_ITEM_ACTIVATE]: this.variationGroupId,
            [CUSTOMER_ENV_ID_API_ACTIVATE]: this.config.envId,
            [ANONYMOUS_ID]: null,
            [QT_API_ITEM]: Date.now() - this.createdAt
        };
        if (this.visitorId && this.anonymousId) {
            apiKeys[VISITOR_ID_API_ITEM] = this.visitorId;
            apiKeys[ANONYMOUS_ID] = this.anonymousId;
        }
        return apiKeys;
    }
    toObject() {
        return {
            ...super.toObject(),
            variationGroupId: this.variationGroupId,
            variationId: this.variationId
        };
    }
    getErrorMessage() {
        return ERROR_MESSAGE;
    }
}
