/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICN_API_ITEM, PM_API_ITEM, SM_API_ITEM, TA_API_ITEM, TC_API_ITEM, TCC_API_ITEM, TID_API_ITEM, TR_API_ITEM, TS_API_ITEM, TT_API_ITEM } from '../enum/FlagshipConstant';
import { HitType } from '../enum/HitType';
import { logError, sprintf } from '../utils/utils';
import { HitAbstract } from './HitAbstract';
export const CURRENCY_ERROR = '{0} must be a string and have exactly 3 letters';
export const ERROR_MESSAGE = 'Transaction Id and Transaction affiliation are required';
export class Transaction extends HitAbstract {
    _transactionId;
    _affiliation;
    _taxes;
    _currency;
    _couponCode;
    _itemCount;
    _shippingMethod;
    _paymentMethod;
    _totalRevenue;
    _shippingCosts;
    get transactionId() {
        return this._transactionId;
    }
    set transactionId(v) {
        if (!this.isNotEmptyString(v, 'transactionId')) {
            return;
        }
        this._transactionId = v;
    }
    get affiliation() {
        return this._affiliation;
    }
    set affiliation(v) {
        if (!this.isNotEmptyString(v, 'affiliation')) {
            return;
        }
        this._affiliation = v;
    }
    get taxes() {
        return this._taxes;
    }
    set taxes(v) {
        if (!this.isNumeric(v, 'taxes')) {
            return;
        }
        this._taxes = v;
    }
    get currency() {
        return this._currency;
    }
    set currency(v) {
        if (!v || typeof v !== 'string' || v.length !== 3) {
            logError(this.config, sprintf(CURRENCY_ERROR, 'currency'), 'currency');
            return;
        }
        this._currency = v;
    }
    get couponCode() {
        return this._couponCode;
    }
    set couponCode(v) {
        if (!this.isNotEmptyString(v, 'couponCode')) {
            return;
        }
        this._couponCode = v;
    }
    get itemCount() {
        return this._itemCount;
    }
    set itemCount(v) {
        if (!this.isInteger(v, 'itemCount')) {
            return;
        }
        this._itemCount = Math.trunc(v);
    }
    get shippingMethod() {
        return this._shippingMethod;
    }
    set shippingMethod(v) {
        if (!this.isNotEmptyString(v, 'shippingMethod')) {
            return;
        }
        this._shippingMethod = v;
    }
    get paymentMethod() {
        return this._paymentMethod;
    }
    set paymentMethod(v) {
        if (!this.isNotEmptyString(v, 'paymentMethod')) {
            return;
        }
        this._paymentMethod = v;
    }
    get totalRevenue() {
        return this._totalRevenue;
    }
    set totalRevenue(v) {
        if (!this.isNumeric(v, 'totalRevenue')) {
            return;
        }
        this._totalRevenue = v;
    }
    get shippingCosts() {
        return this._shippingCosts;
    }
    set shippingCosts(v) {
        if (!this.isNumeric(v, 'shippingCosts')) {
            return;
        }
        this._shippingCosts = v;
    }
    constructor(param) {
        super({
            type: HitType.TRANSACTION,
            userIp: param.userIp,
            screenResolution: param.screenResolution,
            locale: param.locale,
            sessionNumber: param.sessionNumber,
            visitorId: param.visitorId,
            anonymousId: param.anonymousId
        });
        const { transactionId, affiliation, taxes, currency, couponCode, itemCount, shippingMethod, paymentMethod, totalRevenue, shippingCosts } = param;
        this.transactionId = transactionId;
        this.affiliation = affiliation;
        if (taxes) {
            this.taxes = taxes;
        }
        if (currency) {
            this.currency = currency;
        }
        if (couponCode) {
            this.couponCode = couponCode;
        }
        if (itemCount) {
            this.itemCount = itemCount;
        }
        if (shippingMethod) {
            this.shippingMethod = shippingMethod;
        }
        if (paymentMethod) {
            this.paymentMethod = paymentMethod;
        }
        if (totalRevenue) {
            this.totalRevenue = totalRevenue;
        }
        if (shippingCosts) {
            this.shippingCosts = shippingCosts;
        }
    }
    isReady(checkParent = true) {
        return !!((!checkParent || super.isReady()) && this.transactionId && this.affiliation);
    }
    toApiKeys() {
        const apiKeys = super.toApiKeys();
        apiKeys[TID_API_ITEM] = this.transactionId;
        apiKeys[TA_API_ITEM] = this.affiliation;
        if (this.taxes) {
            apiKeys[TT_API_ITEM] = this.taxes;
        }
        if (this.currency) {
            apiKeys[TC_API_ITEM] = this.currency;
        }
        if (this.couponCode) {
            apiKeys[TCC_API_ITEM] = this.couponCode;
        }
        if (this.itemCount) {
            apiKeys[ICN_API_ITEM] = this.itemCount;
        }
        if (this.shippingMethod) {
            apiKeys[SM_API_ITEM] = this.shippingMethod;
        }
        if (this.paymentMethod) {
            apiKeys[PM_API_ITEM] = this.paymentMethod;
        }
        if (this.totalRevenue) {
            apiKeys[TR_API_ITEM] = this.totalRevenue;
        }
        if (this.shippingCosts) {
            apiKeys[TS_API_ITEM] = this.shippingCosts;
        }
        return apiKeys;
    }
    toObject() {
        return {
            ...super.toObject(),
            transactionId: this.transactionId,
            affiliation: this.affiliation,
            taxes: this.taxes,
            currency: this.currency,
            couponCode: this.couponCode,
            itemCount: this.itemCount,
            shippingMethod: this.shippingMethod,
            paymentMethod: this.paymentMethod,
            totalRevenue: this.totalRevenue,
            shippingCosts: this.shippingCosts
        };
    }
    getErrorMessage() {
        return ERROR_MESSAGE;
    }
}
