import { BatchTriggeredBy } from '../enum/BatchTriggeredBy';
import { TrackingManagerAbstract } from './TrackingManagerAbstract';
export class TrackingManager extends TrackingManagerAbstract {
    async activateFlag(hit) {
        await this.strategy.activateFlag(hit);
    }
    async addHit(hit) {
        await this.strategy.addHit(hit);
    }
    async sendBatch() {
        await this.strategy.sendBatch(BatchTriggeredBy.Flush);
    }
}
