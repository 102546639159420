import { HttpError } from './HttpError';
import { REQUEST_TIME_OUT } from '../enum/index';
import { myFetch, LocalAbortController } from '../depsNode.native';
export class HttpClient {
    async getResponse(response) {
        const applicationType = response.headers.get('Content-Type');
        const checkJson = applicationType === 'application/json';
        let body;
        const headers = {};
        response.headers.forEach((value, key) => {
            headers[key] = value;
        });
        if (checkJson && response.ok && response.status !== 204) {
            body = await response.json();
        }
        if (response.status >= 400) {
            const bodyString = await response.text();
            throw new HttpError(response.status, bodyString || response.statusText, headers);
        }
        return {
            status: response.status,
            body,
            headers
        };
    }
    async getAsync(url, options) {
        const c = new LocalAbortController();
        const id = setTimeout(() => c.abort(), (options?.timeout ? options.timeout : REQUEST_TIME_OUT) * 1000);
        try {
            const response = await myFetch(url, {
                method: 'GET',
                headers: options?.headers,
                signal: c.signal,
                keepalive: true,
                next: options?.nextFetchConfig
            });
            return this.getResponse(response);
        }
        finally {
            clearTimeout(id);
        }
    }
    async postAsync(url, options) {
        const c = new LocalAbortController();
        const id = setTimeout(() => c.abort(), options.timeout ? options.timeout * 1000 : REQUEST_TIME_OUT * 1000);
        try {
            const response = await myFetch(url, {
                method: 'POST',
                headers: options.headers,
                body: JSON.stringify(options.body),
                signal: c.signal,
                keepalive: true,
                next: options?.nextFetchConfig
            });
            return this.getResponse(response);
        }
        finally {
            clearTimeout(id);
        }
    }
}
