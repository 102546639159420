import { EventEmitter } from '../depsNode.native';
import { EMIT_READY } from '../enum/index';
export class Visitor extends EventEmitter {
    visitorDelegate;
    constructor(visitorDelegate) {
        super();
        this.visitorDelegate = visitorDelegate;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.visitorDelegate.on(EMIT_READY, (err) => {
            this.emit(EMIT_READY, err);
        });
    }
    getModificationsArray() {
        return this.visitorDelegate.getModificationsArray();
    }
    getFlagsDataArray() {
        return this.visitorDelegate.getFlagsDataArray();
    }
    get visitorId() {
        return this.visitorDelegate.visitorId;
    }
    set visitorId(v) {
        this.visitorDelegate.visitorId = v;
    }
    get anonymousId() {
        return this.visitorDelegate.anonymousId;
    }
    get hasConsented() {
        return this.visitorDelegate.hasConsented;
    }
    setConsent(hasConsented) {
        this.visitorDelegate.setConsent(hasConsented);
    }
    get config() {
        return this.visitorDelegate.config;
    }
    get context() {
        return this.visitorDelegate.context;
    }
    get flagsData() {
        return this.visitorDelegate.flagsData;
    }
    get modifications() {
        return this.visitorDelegate.flagsData;
    }
    updateContext(context, value) {
        this.visitorDelegate.updateContext(context, value);
    }
    clearContext() {
        this.visitorDelegate.clearContext();
    }
    getFlag(key, defaultValue) {
        return this.visitorDelegate.getFlag(key, defaultValue);
    }
    getModification(params) {
        return this.visitorDelegate.getModification(params);
    }
    getModificationSync(params) {
        return this.visitorDelegate.getModificationSync(params);
    }
    getModifications(params, activateAll) {
        return this.visitorDelegate.getModifications(params, activateAll);
    }
    getModificationsSync(params, activateAll) {
        return this.visitorDelegate.getModificationsSync(params, activateAll);
    }
    getModificationInfo(key) {
        return this.visitorDelegate.getModificationInfo(key);
    }
    getModificationInfoSync(key) {
        return this.visitorDelegate.getModificationInfoSync(key);
    }
    synchronizeModifications() {
        return this.visitorDelegate.synchronizeModifications();
    }
    fetchFlags() {
        return this.visitorDelegate.fetchFlags();
    }
    activateModification(key) {
        return this.visitorDelegate.activateModification(key);
    }
    activateModifications(params) {
        return this.visitorDelegate.activateModifications(params);
    }
    sendHit(hit) {
        return this.visitorDelegate.sendHit(hit);
    }
    sendHits(hits) {
        return this.visitorDelegate.sendHits(hits);
    }
    getAllModifications(activate = false) {
        return this.visitorDelegate.getAllModifications(activate);
    }
    getModificationsForCampaign(campaignId, activate = false) {
        return this.visitorDelegate.getModificationsForCampaign(campaignId, activate);
    }
    getAllFlagsData(activate = false) {
        return this.visitorDelegate.getAllFlagsData(activate);
    }
    getFlatsDataForCampaign(campaignId, activate = false) {
        return this.visitorDelegate.getFlatsDataForCampaign(campaignId, activate);
    }
    authenticate(visitorId) {
        this.visitorDelegate.authenticate(visitorId);
    }
    unauthenticate() {
        this.visitorDelegate.unauthenticate();
    }
}
