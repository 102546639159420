import { Visitor } from '../visitor/Visitor';
import { FlagshipStatus } from '../enum/FlagshipStatus';
import { DecisionMode, BucketingConfig, DecisionApiConfig } from '../config/index';
import { ConfigManager } from '../config/ConfigManager';
import { ApiManager } from '../decision/ApiManager';
import { TrackingManager } from '../api/TrackingManager';
import { FlagshipLogManager } from '../utils/FlagshipLogManager';
import { isBrowser, logDebugSprintf, logError, logInfo, logInfoSprintf, sprintf, uuidV4 } from '../utils/utils';
import { INITIALIZATION_PARAM_ERROR, INITIALIZATION_STARTING, NEW_VISITOR_NOT_READY, PROCESS_INITIALIZATION, PROCESS_NEW_VISITOR, SDK_INFO, SDK_STARTED_INFO, PROCESS_SDK_STATUS, SDK_STATUS_CHANGED, SAVE_VISITOR_INSTANCE } from '../enum/index';
import { VisitorDelegate } from '../visitor/VisitorDelegate';
import { BucketingManager } from '../decision/BucketingManager';
import { MurmurHash } from '../utils/MurmurHash';
import { HttpClient } from '../utils/HttpClient';
import { DefaultHitCache } from '../cache/DefaultHitCache';
import { DefaultVisitorCache } from '../cache/DefaultVisitorCache';
import { EdgeManager } from '../decision/EdgeManager';
import { EdgeConfig } from '../config/EdgeConfig';
import { VisitorAbstract } from '../visitor/VisitorAbstract';
export class Flagship {
    // eslint-disable-next-line no-use-before-define
    static _instance;
    _configManager;
    _config;
    _status;
    _visitorInstance;
    instanceId;
    lastInitializationTimestamp;
    set configManager(value) {
        this._configManager = value;
    }
    get configManager() {
        return this._configManager;
    }
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        this.instanceId = uuidV4();
        // singleton
    }
    static getInstance() {
        if (!this._instance) {
            this._instance = new this();
        }
        return this._instance;
    }
    setStatus(status) {
        if (this._status === status) {
            return;
        }
        this._status = status;
        VisitorAbstract.SdkStatus = status;
        const statusChanged = this.getConfig()?.statusChangedCallback;
        logInfoSprintf(this._config, PROCESS_SDK_STATUS, SDK_STATUS_CHANGED, FlagshipStatus[status]);
        if (this.getConfig().decisionMode !== DecisionMode.BUCKETING_EDGE) {
            if (status === FlagshipStatus.READY) {
                this.configManager?.trackingManager?.startBatchingLoop();
            }
            if (status === FlagshipStatus.NOT_INITIALIZED) {
                this.configManager?.trackingManager?.stopBatchingLoop();
            }
        }
        if (statusChanged) {
            statusChanged(status);
        }
    }
    /**
     * Return current status of Flagship SDK.
     */
    static getStatus() {
        return this.getInstance()._status;
    }
    /**
     * Return current status of Flagship SDK.
     */
    getStatus() {
        return this._status;
    }
    /**
     * Return the current config set by the customer and used by the SDK.
     */
    static getConfig() {
        return this.getInstance()._config;
    }
    /**
     * Return the current config set by the customer and used by the SDK.
     */
    getConfig() {
        return this._config;
    }
    /**
     * Return the last visitor created if isNewInstance key is false. Return undefined otherwise.
     */
    getVisitor() {
        return this._visitorInstance;
    }
    /**
     * Return the last visitor created if isNewInstance key is false. Return undefined otherwise.
     */
    static getVisitor() {
        return this.getInstance().getVisitor();
    }
    buildConfig(config) {
        let newConfig;
        switch (config?.decisionMode) {
            case DecisionMode.BUCKETING:
                newConfig = new BucketingConfig(config);
                break;
            case DecisionMode.BUCKETING_EDGE:
                newConfig = new EdgeConfig(config);
                break;
            default:
                newConfig = new DecisionApiConfig(config);
                break;
        }
        return newConfig;
    }
    buildDecisionManager(flagship, config, httpClient) {
        let decisionManager;
        const setStatus = (status) => {
            flagship.setStatus(status);
        };
        switch (config.decisionMode) {
            case DecisionMode.BUCKETING:
                decisionManager = new BucketingManager(httpClient, config, new MurmurHash());
                decisionManager.statusChangedCallback(setStatus);
                decisionManager.startPolling();
                break;
            case DecisionMode.BUCKETING_EDGE:
                decisionManager = new EdgeManager(httpClient, config, new MurmurHash());
                decisionManager.statusChangedCallback(setStatus);
                break;
            default:
                decisionManager = new ApiManager(httpClient, config);
                decisionManager.statusChangedCallback(setStatus);
                break;
        }
        return decisionManager;
    }
    /**
     * Start the flagship SDK, with a custom configuration implementation
     * @param {string} envId : Environment id provided by Flagship.
     * @param {string} apiKey : Secure api key provided by Flagship.
     * @param {IFlagshipConfig} config : (optional) SDK configuration.
     */
    static start(envId, apiKey, config) {
        const flagship = this.getInstance();
        const localConfig = flagship.buildConfig(config);
        localConfig.envId = envId;
        localConfig.apiKey = apiKey;
        flagship._config = localConfig;
        flagship.setStatus(FlagshipStatus.STARTING);
        // check custom logger
        if (!localConfig.onLog && !localConfig.logManager) {
            localConfig.logManager = new FlagshipLogManager();
        }
        if (!envId || !apiKey) {
            flagship.setStatus(FlagshipStatus.NOT_INITIALIZED);
            logError(localConfig, INITIALIZATION_PARAM_ERROR, PROCESS_INITIALIZATION);
            return flagship;
        }
        logDebugSprintf(localConfig, PROCESS_INITIALIZATION, INITIALIZATION_STARTING, SDK_INFO.version, localConfig.decisionMode, localConfig);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!localConfig.hitCacheImplementation && isBrowser()) {
            localConfig.hitCacheImplementation = new DefaultHitCache();
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!localConfig.visitorCacheImplementation && isBrowser()) {
            localConfig.visitorCacheImplementation = new DefaultVisitorCache();
        }
        const httpClient = new HttpClient();
        const decisionManager = flagship.configManager?.decisionManager;
        if (decisionManager instanceof BucketingManager && localConfig.decisionMode !== DecisionMode.BUCKETING_EDGE) {
            decisionManager.stopPolling();
        }
        let trackingManager = flagship.configManager?.trackingManager;
        if (!trackingManager) {
            trackingManager = new TrackingManager(httpClient, localConfig, flagship.instanceId);
        }
        flagship.configManager = new ConfigManager(localConfig, decisionManager, trackingManager);
        flagship.configManager.decisionManager = flagship.buildDecisionManager(flagship, localConfig, httpClient);
        flagship.configManager.decisionManager.trackingManager = trackingManager;
        flagship.configManager.decisionManager.flagshipInstanceId = flagship.instanceId;
        if (flagship._status === FlagshipStatus.STARTING) {
            flagship.setStatus(FlagshipStatus.READY);
        }
        logInfo(localConfig, sprintf(SDK_STARTED_INFO, SDK_INFO.version, FlagshipStatus[flagship._status]), PROCESS_INITIALIZATION);
        flagship.lastInitializationTimestamp = new Date().toISOString();
        return flagship;
    }
    async close() {
        await Flagship.close();
    }
    /**
     * When called, it will batch and send all hits that are in the pool before the application is closed
     */
    static async close() {
        await this._instance?.configManager?.trackingManager?.sendBatch();
    }
    newVisitor(param1, param2) {
        return Flagship.newVisitor(param1, param2);
    }
    static newVisitor(param1, param2) {
        let visitorId;
        let context;
        let isAuthenticated = false;
        let hasConsented = true;
        let initialFlagsData;
        let initialCampaigns;
        const isServerSide = !isBrowser();
        let isNewInstance = isServerSide;
        if (typeof param1 === 'string' || param1 === null) {
            visitorId = param1 || undefined;
            context = param2 || {};
        }
        else {
            visitorId = param1?.visitorId;
            context = param1?.context || {};
            isAuthenticated = !!param1?.isAuthenticated;
            hasConsented = param1?.hasConsented ?? true;
            initialFlagsData = param1?.initialFlagsData || param1?.initialModifications;
            initialCampaigns = param1?.initialCampaigns;
            isNewInstance = param1?.isNewInstance ?? isNewInstance;
        }
        if (!this._instance?.configManager) {
            const flagship = this.getInstance();
            const config = new DecisionApiConfig();
            config.logManager = new FlagshipLogManager();
            flagship._config = config;
            const httpClient = new HttpClient();
            const trackingManager = new TrackingManager(httpClient, config);
            const decisionManager = new ApiManager(httpClient, config);
            flagship.configManager = new ConfigManager(config, decisionManager, trackingManager);
            logError(this.getConfig(), NEW_VISITOR_NOT_READY, PROCESS_NEW_VISITOR);
        }
        const visitorDelegate = new VisitorDelegate({
            visitorId,
            context,
            isAuthenticated,
            hasConsented,
            configManager: this.getInstance().configManager,
            initialModifications: initialFlagsData,
            initialCampaigns,
            initialFlagsData,
            monitoringData: {
                instanceId: this.getInstance().instanceId,
                lastInitializationTimestamp: this.getInstance().lastInitializationTimestamp,
                initialCampaigns,
                initialFlagsData
            }
        });
        const visitor = new Visitor(visitorDelegate);
        this.getInstance()._visitorInstance = !isNewInstance ? visitor : undefined;
        if (!isNewInstance) {
            logDebugSprintf(this.getConfig(), PROCESS_NEW_VISITOR, SAVE_VISITOR_INSTANCE, visitor.visitorId);
        }
        if (this.getConfig().fetchNow && this.getConfig().decisionMode !== DecisionMode.BUCKETING_EDGE) {
            visitor.fetchFlags();
        }
        return visitor;
    }
}
