import { CUSTOMER_ENV_ID_API_ITEM, DS_API_ITEM, QT_API_ITEM, T_API_ITEM } from '../enum/FlagshipConstant';
import { HitAbstract } from './HitAbstract';
export const BATCH = 'BATCH';
export const ERROR_MESSAGE = 'Please check required fields';
export class Batch extends HitAbstract {
    _hits;
    get hits() {
        return this._hits;
    }
    set hits(v) {
        this._hits = v;
    }
    constructor(params) {
        super({ ...params, visitorId: '', anonymousId: '', type: BATCH });
        this.hits = params.hits;
    }
    isReady() {
        return !!(super.isReady() &&
            this.hits &&
            this.hits.length > 0 &&
            this.hits.every(hit => hit.isReady(false)));
    }
    toApiKeys() {
        const apiKeys = {
            [DS_API_ITEM]: this.ds,
            [CUSTOMER_ENV_ID_API_ITEM]: `${this.config?.envId}`,
            [T_API_ITEM]: this.type,
            [QT_API_ITEM]: Date.now() - this.createdAt
        };
        apiKeys.h = this.hits.map(hit => {
            const hitKeys = hit.toApiKeys();
            delete hitKeys[DS_API_ITEM];
            delete hitKeys[CUSTOMER_ENV_ID_API_ITEM];
            return hitKeys;
        });
        return apiKeys;
    }
    getErrorMessage() {
        return ERROR_MESSAGE;
    }
}
