import { IC_API_ITEM, IN_API_ITEM, IP_API_ITEM, IQ_API_ITEM, IV_API_ITEM, TID_API_ITEM } from '../enum/FlagshipConstant';
import { HitType } from '../enum/HitType';
import { HitAbstract } from './HitAbstract';
export const ERROR_MESSAGE = 'Transaction Id, Item name and item code are required';
export class Item extends HitAbstract {
    _transactionId;
    _productName;
    _productSku;
    _itemPrice;
    _itemQuantity;
    _itemCategory;
    get transactionId() {
        return this._transactionId;
    }
    /**
     * Specify transaction unique identifier.
     */
    set transactionId(v) {
        if (!this.isNotEmptyString(v, 'transactionId')) {
            return;
        }
        this._transactionId = v;
    }
    get productName() {
        return this._productName;
    }
    /**
     * Specify name of the item product.
     */
    set productName(v) {
        if (!this.isNotEmptyString(v, 'productName')) {
            return;
        }
        this._productName = v;
    }
    get productSku() {
        return this._productSku;
    }
    /**
     * Specify the SKU or item code.
     */
    set productSku(v) {
        if (!this.isNotEmptyString(v, 'productSku')) {
            return;
        }
        this._productSku = v;
    }
    get itemPrice() {
        return this._itemPrice;
    }
    /**
     * Specify the price for a single item
     */
    set itemPrice(v) {
        if (!this.isNumeric(v, 'itemPrice')) {
            return;
        }
        this._itemPrice = v;
    }
    get itemQuantity() {
        return this._itemQuantity;
    }
    /**
     * Specify the number of items purchased.
     */
    set itemQuantity(v) {
        if (!this.isInteger(v, 'itemQuantity')) {
            return;
        }
        this._itemQuantity = Math.trunc(v);
    }
    get itemCategory() {
        return this._itemCategory;
    }
    /**
     * Specify the category that the item belongs to
     */
    set itemCategory(v) {
        if (!this.isNotEmptyString(v, 'itemCategory')) {
            return;
        }
        this._itemCategory = v;
    }
    /**
     *Item constructor.
     * @param transactionId : Transaction unique identifier.
     * @param productName : Name of the item product.
     * @param productSku : The SKU or item code.
     */
    constructor(param) {
        super({
            type: HitType.ITEM,
            userIp: param.userIp,
            screenResolution: param.screenResolution,
            locale: param.locale,
            sessionNumber: param.sessionNumber,
            visitorId: param.visitorId,
            anonymousId: param.anonymousId
        });
        const { transactionId, productName, productSku, itemCategory, itemPrice, itemQuantity } = param;
        this.transactionId = transactionId;
        this.productName = productName;
        this.productSku = productSku;
        if (itemCategory) {
            this.itemCategory = itemCategory;
        }
        if (itemPrice) {
            this.itemPrice = itemPrice;
        }
        if (itemQuantity) {
            this.itemQuantity = itemQuantity;
        }
    }
    isReady(checkParent = true) {
        return !!((!checkParent || super.isReady()) &&
            this.transactionId &&
            this.productName &&
            this.productSku);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toApiKeys() {
        const apiKeys = super.toApiKeys();
        apiKeys[TID_API_ITEM] = this.transactionId;
        apiKeys[IN_API_ITEM] = this.productName;
        apiKeys[IC_API_ITEM] = this.productSku;
        if (this.itemPrice) {
            apiKeys[IP_API_ITEM] = this.itemPrice;
        }
        if (this.itemQuantity) {
            apiKeys[IQ_API_ITEM] = this.itemQuantity;
        }
        if (this.itemCategory) {
            apiKeys[IV_API_ITEM] = this.itemCategory;
        }
        return apiKeys;
    }
    toObject() {
        return {
            ...super.toObject(),
            transactionId: this.transactionId,
            productName: this.productName,
            productSku: this.productSku,
            itemPrice: this.itemPrice,
            itemQuantity: this.itemQuantity,
            itemCategory: this.itemCategory
        };
    }
    getErrorMessage() {
        return ERROR_MESSAGE;
    }
}
