import { EVENT_ACTION_API_ITEM, EVENT_CATEGORY_API_ITEM, EVENT_LABEL_API_ITEM, EVENT_VALUE_API_ITEM } from '../enum/FlagshipConstant';
import { HitType } from '../enum/HitType';
import { logError } from '../utils/utils';
import { HitAbstract } from './HitAbstract';
export const ERROR_MESSAGE = 'event category and event action are required';
export const CATEGORY_ERROR = 'The category value must be either EventCategory::ACTION_TRACKING or EventCategory::ACTION_TRACKING';
export const VALUE_FIELD_ERROR = 'value must be an integer and be >= 0';
export var EventCategory;
(function (EventCategory) {
    EventCategory["ACTION_TRACKING"] = "Action Tracking";
    EventCategory["USER_ENGAGEMENT"] = "User Engagement";
})(EventCategory || (EventCategory = {}));
export class Event extends HitAbstract {
    _category;
    _action;
    _label;
    _value;
    get category() {
        return this._category;
    }
    /**
     * Specify Action Tracking or User Engagement.
     */
    set category(v) {
        if (!(Object.values(EventCategory).includes(v))) {
            logError(this.config, CATEGORY_ERROR, 'category');
            return;
        }
        this._category = v;
    }
    get action() {
        return this._action;
    }
    /**
     * Specify Event name that will also serve as the KPI
     * that you will have inside your reporting
     */
    set action(v) {
        if (!this.isNotEmptyString(v, 'action')) {
            return;
        }
        this._action = v;
    }
    get label() {
        return this._label;
    }
    /**
     * Specify additional description of event.
     */
    set label(v) {
        if (!this.isNotEmptyString(v, 'label')) {
            return;
        }
        this._label = v;
    }
    get value() {
        return this._value;
    }
    /**
     * Specify the monetary value associated with an event
     * (e.g. you earn 10 to 100 euros depending on the quality of lead generated).
     *
     * <br/> NOTE: this value must be non-negative.
     */
    set value(v) {
        if (!Number.isInteger(v) || v < 0) {
            logError(this.config, VALUE_FIELD_ERROR, 'value');
            return;
        }
        this._value = v;
    }
    constructor(param) {
        super({
            type: HitType.EVENT,
            userIp: param.userIp,
            screenResolution: param.screenResolution,
            locale: param.locale,
            sessionNumber: param.sessionNumber,
            visitorId: param.visitorId,
            anonymousId: param.anonymousId
        });
        const { category, action, label, value } = param;
        this.category = category;
        this.action = action;
        if (label) {
            this.label = label;
        }
        if (value) {
            this.value = value;
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toApiKeys() {
        const apiKeys = super.toApiKeys();
        apiKeys[EVENT_CATEGORY_API_ITEM] = this.category;
        apiKeys[EVENT_ACTION_API_ITEM] = this.action;
        if (this.label) {
            apiKeys[EVENT_LABEL_API_ITEM] = this.label;
        }
        if (this.value) {
            apiKeys[EVENT_VALUE_API_ITEM] = this.value;
        }
        return apiKeys;
    }
    toObject() {
        return {
            ...super.toObject(),
            category: this.category,
            action: this.action,
            label: this.label,
            value: this.value
        };
    }
    isReady(checkParent = true) {
        return !!((!checkParent || super.isReady()) && this.category && this.action);
    }
    getErrorMessage() {
        return ERROR_MESSAGE;
    }
}
