export class FlagMetadata {
    campaignId;
    campaignName;
    variationGroupId;
    variationGroupName;
    variationId;
    variationName;
    isReference;
    campaignType;
    slug;
    constructor(flagMetadata) {
        const { campaignId, variationGroupId, variationId, isReference, campaignType, slug, variationGroupName, variationName, campaignName } = flagMetadata;
        this.campaignId = campaignId;
        this.variationGroupId = variationGroupId;
        this.variationId = variationId;
        this.isReference = isReference;
        this.campaignType = campaignType;
        this.campaignName = campaignName;
        this.variationGroupName = variationGroupName;
        this.variationName = variationName;
        this.slug = slug;
    }
    static Empty() {
        return new FlagMetadata({
            campaignId: '',
            campaignName: '',
            campaignType: '',
            variationId: '',
            variationName: '',
            variationGroupId: '',
            variationGroupName: '',
            isReference: false,
            slug: null
        });
    }
}
