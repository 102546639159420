export class ConfigManager {
    _config;
    _decisionManager;
    _trackingManager;
    constructor(config, decisionManager, trackingManager) {
        this._config = config;
        this._decisionManager = decisionManager;
        this._trackingManager = trackingManager;
    }
    get config() {
        return this._config;
    }
    set config(value) {
        this._config = value;
    }
    get decisionManager() {
        return this._decisionManager;
    }
    set decisionManager(value) {
        this._decisionManager = value;
    }
    get trackingManager() {
        return this._trackingManager;
    }
    set trackingManager(value) {
        this._trackingManager = value;
    }
}
