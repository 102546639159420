export var FlagshipStatus;
(function (FlagshipStatus) {
    /**
        * It is the default initial status. This status remains until the sdk has been initialized successfully.
        * Flagship SDK has not been started or initialized successfully.
        * @deprecated in v2, use FlagshipStatus::NOT_INITIALIZED instead of
        */
    FlagshipStatus[FlagshipStatus["NOT_READY"] = 0] = "NOT_READY";
    /**
     * It is the default initial status. This status remains until the sdk has been initialized successfully.
     */
    FlagshipStatus[FlagshipStatus["NOT_INITIALIZED"] = 0] = "NOT_INITIALIZED";
    /**
     * Flagship SDK is starting.
     */
    FlagshipStatus[FlagshipStatus["STARTING"] = 1] = "STARTING";
    /**
     * Flagship SDK has been started successfully but is still polling campaigns.
     */
    FlagshipStatus[FlagshipStatus["POLLING"] = 2] = "POLLING";
    /**
     * Flagship SDK is ready but is running in Panic mode: All features are disabled except the one which refresh this status.
     */
    FlagshipStatus[FlagshipStatus["READY_PANIC_ON"] = 3] = "READY_PANIC_ON";
    /**
     * Flagship SDK is ready to use.
     */
    FlagshipStatus[FlagshipStatus["READY"] = 4] = "READY";
})(FlagshipStatus || (FlagshipStatus = {}));
