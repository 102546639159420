import { DEFAULT_POLLING_INTERVAL } from '../enum/index';
import { DecisionMode } from './DecisionMode';
import { FlagshipConfig } from './FlagshipConfig';
export class BucketingConfig extends FlagshipConfig {
    constructor(param) {
        super({ ...param, decisionMode: DecisionMode.BUCKETING });
        this.pollingInterval = param?.pollingInterval ?? DEFAULT_POLLING_INTERVAL;
        this.onBucketingFail = param?.onBucketingFail;
        this.onBucketingSuccess = param?.onBucketingSuccess;
        this.onBucketingUpdated = param?.onBucketingUpdated;
        this.fetchThirdPartyData = param?.fetchThirdPartyData;
    }
}
