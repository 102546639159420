export const CLIENT_CACHE_KEY = 'FS_CLIENT_VISITOR';
export const cacheVisitor = {
    saveVisitorProfile(visitorProfile) {
        try {
            localStorage.setItem(CLIENT_CACHE_KEY, JSON.stringify(visitorProfile));
        }
        catch (error) {
        }
    },
    loadVisitorProfile() {
        let data = null;
        try {
            data = localStorage.getItem(CLIENT_CACHE_KEY);
        }
        catch (error) {
        }
        return data ? JSON.parse(data) : null;
    }
};
