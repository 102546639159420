import { FLAG_USER_EXPOSED, METHOD_DEACTIVATED_CONSENT_ERROR } from '../enum/index';
import { logInfo, sprintf } from '../utils/utils';
import { DefaultStrategy } from './DefaultStrategy';
export class NoConsentStrategy extends DefaultStrategy {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async activateModification(_params) {
        this.log('activateModification');
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async activateModifications(_params) {
        this.log('activateModifications');
    }
    async lookupHits() {
        //
    }
    async lookupVisitor() {
        //
    }
    async cacheVisitor() {
        //
    }
    async cacheHit() {
        //
    }
    fetchVisitorCampaigns() {
        return [];
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendHit(_hit) {
        this.log('sendHit');
        return Promise.resolve();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendHits(_hits) {
        this.log('sendHits');
        return Promise.resolve();
    }
    async visitorExposed() {
        this.log(FLAG_USER_EXPOSED);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendTroubleshootingHit(_hit) {
        return Promise.resolve();
    }
    log(methodName) {
        logInfo(this.config, sprintf(METHOD_DEACTIVATED_CONSENT_ERROR, methodName, this.visitor.visitorId), methodName);
    }
}
