import { DL_API_ITEM } from '../enum/FlagshipConstant';
import { HitType } from '../enum/HitType';
import { HitAbstract } from './HitAbstract';
export const ERROR_MESSAGE = 'Screen name is required';
export class Screen extends HitAbstract {
    _documentLocation;
    get documentLocation() {
        return this._documentLocation;
    }
    set documentLocation(v) {
        if (!this.isNotEmptyString(v, 'documentLocation')) {
            return;
        }
        this._documentLocation = v;
    }
    constructor(param) {
        super({
            type: HitType.SCREEN_VIEW,
            userIp: param.userIp,
            screenResolution: param.screenResolution,
            locale: param.locale,
            sessionNumber: param.sessionNumber,
            visitorId: param.visitorId,
            anonymousId: param.anonymousId
        });
        this.documentLocation = param.documentLocation;
    }
    isReady(checkParent = true) {
        return !!((!checkParent || super.isReady()) && this.documentLocation);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toApiKeys() {
        const apiKeys = super.toApiKeys();
        apiKeys[DL_API_ITEM] = this.documentLocation;
        return apiKeys;
    }
    toObject() {
        return {
            ...super.toObject(),
            documentLocation: this.documentLocation
        };
    }
    getErrorMessage() {
        return ERROR_MESSAGE;
    }
}
