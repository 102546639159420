export var DecisionMode;
(function (DecisionMode) {
    /**
     *
     * Flagship SDK mode decision api
     * @deprecated use DECISION_API instead of
     */
    DecisionMode["API"] = "API";
    /**
     *   /**
     * Flagship SDK mode decision api
     */
    DecisionMode["DECISION_API"] = "DECISION-API";
    /**
     * Flagship SDK mode bucketing
     */
    DecisionMode["BUCKETING"] = "BUCKETING";
    DecisionMode["BUCKETING_EDGE"] = "BUCKETING_EDGE";
})(DecisionMode || (DecisionMode = {}));
