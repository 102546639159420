export var HitType;
(function (HitType) {
    /**
     * User has seen a URL
     *
     */
    HitType["PAGE_VIEW"] = "PAGEVIEW";
    /**
     * User has seen a URL
     *
     */
    HitType["PAGE"] = "PAGEVIEW";
    /**
     * User has seen a screen.
     *
     */
    HitType["SCREEN_VIEW"] = "SCREENVIEW";
    /**
     * User has seen a screen.
     *
     */
    HitType["SCREEN"] = "SCREENVIEW";
    /**
     * User has made a transaction.
     *
     */
    HitType["TRANSACTION"] = "TRANSACTION";
    /**
     * Item bought in a transaction.
     *
     */
    HitType["ITEM"] = "ITEM";
    /**
     * User has made a specific action.
     *
     */
    HitType["EVENT"] = "EVENT";
})(HitType || (HitType = {}));
