import { DL_API_ITEM } from '../enum/FlagshipConstant';
import { HitType } from '../enum/HitType';
import { HitAbstract } from './HitAbstract';
export const ERROR_MESSAGE = 'documentLocation url is required';
export class Page extends HitAbstract {
    _documentLocation;
    get documentLocation() {
        return this._documentLocation;
    }
    set documentLocation(v) {
        if (!this.isNotEmptyString(v, 'documentLocation')) {
            return;
        }
        this._documentLocation = v;
    }
    constructor(page) {
        super({
            type: HitType.PAGE_VIEW,
            userIp: page.userIp,
            screenResolution: page.screenResolution,
            locale: page.locale,
            sessionNumber: page.sessionNumber,
            visitorId: page.visitorId,
            anonymousId: page.anonymousId
        });
        this.documentLocation = page.documentLocation;
    }
    isReady(checkParent = true) {
        return !!((!checkParent || super.isReady()) && this.documentLocation);
    }
    toApiKeys() {
        const apiKeys = super.toApiKeys();
        apiKeys[DL_API_ITEM] = this.documentLocation;
        return apiKeys;
    }
    toObject() {
        return {
            ...super.toObject(),
            documentLocation: this.documentLocation
        };
    }
    getErrorMessage() {
        return ERROR_MESSAGE;
    }
}
