import { BASE_API_URL, DEFAULT_DEDUPLICATION_TIME, FETCH_FLAG_BUFFERING_DEFAULT_TIME, LogLevel, REQUEST_TIME_OUT, SDK_INFO, TYPE_ERROR } from '../enum/index';
import { logError, sprintf } from '../utils/utils';
import { TrackingManagerConfig } from './TrackingManagerConfig';
import { version as SDK_VERSION } from '../sdkVersion';
import { DecisionMode } from './DecisionMode';
export const statusChangeError = 'statusChangedCallback must be a function';
export class FlagshipConfig {
    _envId;
    _apiKey;
    _decisionMode;
    _timeout;
    _logLevel;
    _statusChangedCallback;
    _logManager;
    _fetchNow;
    _pollingInterval;
    _onBucketingFail;
    _onBucketingSuccess;
    _onBucketingUpdated;
    _enableClientCache;
    _initialBucketing;
    _decisionApiUrl;
    _hitDeduplicationTime;
    _visitorCacheImplementation;
    _hitCacheImplementation;
    _disableCache;
    _trackingManagerConfig;
    _onUserExposure;
    _onVisitorExposed;
    _fetchThirdPartyData;
    _nextFetchConfig;
    _fetchFlagsBufferingTime;
    _enableAnalytics;
    get disableDeveloperUsageTracking() {
        return this._enableAnalytics;
    }
    set disableDeveloperUsageTracking(v) {
        this._enableAnalytics = v;
    }
    get fetchFlagsBufferingTime() {
        return this._fetchFlagsBufferingTime;
    }
    set fetchFlagsBufferingTime(v) {
        this._fetchFlagsBufferingTime = v;
    }
    get nextFetchConfig() {
        return this._nextFetchConfig;
    }
    set nextFetchConfig(v) {
        this._nextFetchConfig = v;
    }
    get fetchThirdPartyData() {
        return this._fetchThirdPartyData;
    }
    set fetchThirdPartyData(v) {
        this._fetchThirdPartyData = v;
    }
    get trackingManagerConfig() {
        return this._trackingManagerConfig;
    }
    _onLog;
    get onLog() {
        return this._onLog;
    }
    set onLog(v) {
        this._onLog = v;
    }
    get onUserExposure() {
        return this._onUserExposure;
    }
    get onVisitorExposed() {
        return this._onVisitorExposed;
    }
    constructor(param) {
        const { envId, apiKey, timeout, logLevel, logManager, statusChangedCallback, fetchNow, decisionMode, enableClientCache, initialBucketing, decisionApiUrl, hitDeduplicationTime, visitorCacheImplementation, hitCacheImplementation, disableCache, language, onUserExposure, sdkVersion, trackingMangerConfig, trackingManagerConfig, onLog, onVisitorExposed, nextFetchConfig, fetchFlagsBufferingTime, disableDeveloperUsageTracking } = param;
        this.initSDKInfo(language, sdkVersion);
        if (logManager) {
            this.logManager = logManager;
        }
        this.fetchFlagsBufferingTime = fetchFlagsBufferingTime ?? FETCH_FLAG_BUFFERING_DEFAULT_TIME;
        this.nextFetchConfig = nextFetchConfig || { revalidate: 20 };
        this._trackingManagerConfig = new TrackingManagerConfig(trackingManagerConfig || trackingMangerConfig || {});
        this.onLog = onLog;
        this.decisionApiUrl = decisionApiUrl || BASE_API_URL;
        this._envId = envId;
        this._apiKey = apiKey;
        this.logLevel = logLevel ?? LogLevel.ALL;
        this.timeout = timeout || REQUEST_TIME_OUT;
        this.fetchNow = typeof fetchNow === 'undefined' || fetchNow;
        this.enableClientCache = typeof enableClientCache === 'undefined' || enableClientCache;
        this._decisionMode = decisionMode || DecisionMode.DECISION_API;
        this._initialBucketing = initialBucketing;
        this.hitDeduplicationTime = hitDeduplicationTime ?? DEFAULT_DEDUPLICATION_TIME;
        this.disableCache = !!disableCache;
        this.disableDeveloperUsageTracking = disableDeveloperUsageTracking || false;
        if (visitorCacheImplementation) {
            this.visitorCacheImplementation = visitorCacheImplementation;
        }
        if (hitCacheImplementation) {
            this.hitCacheImplementation = hitCacheImplementation;
        }
        this.statusChangedCallback = statusChangedCallback;
        this._onUserExposure = onUserExposure;
        this._onVisitorExposed = onVisitorExposed;
    }
    initSDKInfo(language, sdkVersion) {
        switch (language) {
            case 1:
                SDK_INFO.name = 'ReactJS';
                SDK_INFO.version = sdkVersion ?? SDK_VERSION;
                break;
            case 2:
                SDK_INFO.name = 'React-Native';
                SDK_INFO.version = sdkVersion ?? SDK_VERSION;
                break;
            default:
                SDK_INFO.name = (typeof window !== 'undefined' && 'Deno' in window) ? 'Deno' : 'Typescript';
                SDK_INFO.version = SDK_VERSION;
                break;
        }
    }
    get initialBucketing() {
        return this._initialBucketing;
    }
    set initialBucketing(v) {
        this._initialBucketing = v;
    }
    get enableClientCache() {
        return this._enableClientCache;
    }
    set enableClientCache(v) {
        this._enableClientCache = v;
    }
    get onBucketingSuccess() {
        return this._onBucketingSuccess;
    }
    set onBucketingSuccess(v) {
        this._onBucketingSuccess = v;
    }
    get onBucketingFail() {
        return this._onBucketingFail;
    }
    set onBucketingFail(v) {
        this._onBucketingFail = v;
    }
    get onBucketingUpdated() {
        return this._onBucketingUpdated;
    }
    set onBucketingUpdated(v) {
        this._onBucketingUpdated = v;
    }
    set envId(value) {
        this._envId = value;
    }
    get envId() {
        return this._envId;
    }
    set apiKey(value) {
        this._apiKey = value;
    }
    get apiKey() {
        return this._apiKey;
    }
    get decisionMode() {
        return this._decisionMode;
    }
    get timeout() {
        return this._timeout;
    }
    set timeout(value) {
        this._timeout = value;
    }
    get logLevel() {
        return this._logLevel;
    }
    set logLevel(value) {
        this._logLevel = value;
    }
    get fetchNow() {
        return this._fetchNow;
    }
    set fetchNow(v) {
        this._fetchNow = v;
    }
    get pollingInterval() {
        return this._pollingInterval;
    }
    set pollingInterval(v) {
        this._pollingInterval = v;
    }
    get hitDeduplicationTime() {
        return this._hitDeduplicationTime;
    }
    set hitDeduplicationTime(v) {
        if (typeof v !== 'number') {
            logError(this, sprintf(TYPE_ERROR, 'hitDeduplicationTime', 'number'), 'hitDeduplicationTime');
            return;
        }
        this._hitDeduplicationTime = v;
    }
    get visitorCacheImplementation() {
        return this._visitorCacheImplementation;
    }
    set visitorCacheImplementation(v) {
        this._visitorCacheImplementation = v;
    }
    get hitCacheImplementation() {
        return this._hitCacheImplementation;
    }
    set hitCacheImplementation(v) {
        this._hitCacheImplementation = v;
    }
    get disableCache() {
        return this._disableCache;
    }
    set disableCache(v) {
        this._disableCache = v;
    }
    get statusChangedCallback() {
        return this._statusChangedCallback;
    }
    set statusChangedCallback(fn) {
        if (fn && typeof fn !== 'function') {
            logError(this, statusChangeError, 'statusChangedCallback');
            return;
        }
        this._statusChangedCallback = fn;
    }
    get logManager() {
        return this._logManager;
    }
    set logManager(value) {
        this._logManager = value;
    }
    get decisionApiUrl() {
        return this._decisionApiUrl;
    }
    set decisionApiUrl(v) {
        if (typeof v !== 'string') {
            logError(this, sprintf(TYPE_ERROR, 'decisionApiUrl', 'string'), 'decisionApiUrl');
            return;
        }
        this._decisionApiUrl = v;
    }
}
