import { BATCH, CUSTOMER_ENV_ID_API_ITEM } from '../enum/index';
export class ActivateBatch {
    _hits;
    _config;
    get hits() {
        return this._hits;
    }
    get config() {
        return this._config;
    }
    constructor(hits, config) {
        this._config = config;
        this._hits = hits;
    }
    toApiKeys() {
        return {
            [CUSTOMER_ENV_ID_API_ITEM]: `${this.config?.envId}`,
            [BATCH]: this.hits.map(x => {
                const apiKeys = x.toApiKeys();
                delete apiKeys[CUSTOMER_ENV_ID_API_ITEM];
                return apiKeys;
            })
        };
    }
}
