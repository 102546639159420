import { CUSTOMER_ENV_ID_API_ITEM, DS_API_ITEM, T_API_ITEM, TYPE_ERROR, VISITOR_ID_API_ITEM, CUSTOMER_UID, USER_IP_API_ITEM, SCREEN_RESOLUTION_API_ITEM, SESSION_NUMBER, USER_LANGUAGE, QT_API_ITEM, SDK_APP } from '../enum/FlagshipConstant';
import { logError, sprintf } from '../utils/utils';
export class HitAbstract {
    _visitorId;
    _config;
    _type;
    _ds;
    _anonymousId;
    _userIp;
    _screenResolution;
    _locale;
    _sessionNumber;
    _key;
    _createdAt;
    get key() {
        return this._key;
    }
    set key(v) {
        this._key = v;
    }
    get sessionNumber() {
        return this._sessionNumber;
    }
    set sessionNumber(v) {
        this._sessionNumber = v;
    }
    get locale() {
        return this._locale;
    }
    set locale(v) {
        this._locale = v;
    }
    get screenResolution() {
        return this._screenResolution;
    }
    set screenResolution(v) {
        this._screenResolution = v;
    }
    get userIp() {
        return this._userIp;
    }
    set userIp(v) {
        this._userIp = v;
    }
    get anonymousId() {
        return this._anonymousId;
    }
    set anonymousId(v) {
        this._anonymousId = v;
    }
    get visitorId() {
        return this._visitorId;
    }
    set visitorId(v) {
        this._visitorId = v;
    }
    get ds() {
        return this._ds;
    }
    set ds(v) {
        this._ds = v;
    }
    get type() {
        return this._type;
    }
    get config() {
        return this._config;
    }
    set config(v) {
        this._config = v;
    }
    get createdAt() {
        return this._createdAt;
    }
    set createdAt(v) {
        this._createdAt = v;
    }
    constructor(hit) {
        const { type, userIp, screenResolution, locale, sessionNumber, visitorId, anonymousId, ds } = hit;
        this._type = type;
        if (userIp) {
            this.userIp = userIp;
        }
        if (screenResolution) {
            this.screenResolution = screenResolution;
        }
        if (locale) {
            this.locale = locale;
        }
        if (sessionNumber) {
            this.sessionNumber = sessionNumber;
        }
        this.visitorId = visitorId;
        this._anonymousId = anonymousId || null;
        this.createdAt = Date.now();
        this.ds = ds || SDK_APP;
    }
    /**
     * Return true if value is a string and not empty, otherwise return false
     * @param value
     * @param itemName
     * @returns
     */
    isNotEmptyString(value, itemName) {
        if (!value || typeof value !== 'string') {
            logError(this.config, sprintf(TYPE_ERROR, itemName, 'string'), itemName);
            return false;
        }
        return true;
    }
    isNumeric(value, itemName) {
        if (!value || typeof value !== 'number') {
            logError(this.config, sprintf(TYPE_ERROR, itemName, 'number'), itemName);
            return false;
        }
        return true;
    }
    isInteger(value, itemName) {
        if (!Number.isInteger(value)) {
            logError(this.config, sprintf(TYPE_ERROR, itemName, 'integer'), itemName);
            return false;
        }
        return true;
    }
    /**
     * Return an object with Api parameters as keys
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toApiKeys() {
        const apiKeys = {
            [VISITOR_ID_API_ITEM]: this.visitorId,
            [DS_API_ITEM]: this.ds,
            [CUSTOMER_ENV_ID_API_ITEM]: `${this.config?.envId}`,
            [T_API_ITEM]: this.type,
            [CUSTOMER_UID]: null,
            [QT_API_ITEM]: Date.now() - this._createdAt
        };
        if (this.userIp) {
            apiKeys[USER_IP_API_ITEM] = this.userIp;
        }
        if (this.screenResolution) {
            apiKeys[SCREEN_RESOLUTION_API_ITEM] = this.screenResolution;
        }
        if (this.locale) {
            apiKeys[USER_LANGUAGE] = this.locale;
        }
        if (this.sessionNumber) {
            apiKeys[SESSION_NUMBER] = this.sessionNumber;
        }
        if (this.visitorId && this.anonymousId) {
            apiKeys[VISITOR_ID_API_ITEM] = this.anonymousId;
            apiKeys[CUSTOMER_UID] = this.visitorId;
        }
        return apiKeys;
    }
    toObject() {
        return {
            key: this.key,
            visitorId: this.visitorId,
            ds: this.ds,
            type: this.type,
            userIp: this.userIp,
            screenResolution: this.screenResolution,
            locale: this.locale,
            sessionNumber: this.sessionNumber,
            anonymousId: this.anonymousId,
            createdAt: this.createdAt
        };
    }
    /**
     * Return true if all required attributes are given, otherwise return false
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isReady(_checkParent = true) {
        return !!(this.visitorId &&
            this.ds &&
            this.config &&
            this.config.envId &&
            this.type);
    }
}
