import { VisitorAbstract } from './VisitorAbstract';
import { Flag } from '../flag/Flags';
import { logWarningSprintf, visitorFlagSyncStatusMessage } from '../utils/utils';
import { GET_FLAG } from '../enum/FlagshipConstant';
import { FlagSynchStatus } from '../enum/FlagSynchStatus';
export class VisitorDelegate extends VisitorAbstract {
    updateContext(context, value) {
        this.getStrategy().updateContext(context, value);
        this.loadPredefinedContext();
    }
    clearContext() {
        this.getStrategy().clearContext();
    }
    getFlag(key, defaultValue) {
        if (this.flagSynchStatus !== FlagSynchStatus.FLAGS_FETCHED) {
            logWarningSprintf(this.config, GET_FLAG, visitorFlagSyncStatusMessage(this.flagSynchStatus), this.visitorId, key);
        }
        return new Flag({ key, visitor: this, defaultValue });
    }
    getModification(params) {
        return this.getStrategy().getModification(params);
    }
    getModificationSync(params) {
        return this.getStrategy().getModificationSync(params);
    }
    getModifications(params, activateAll) {
        return this.getStrategy().getModifications(params, activateAll);
    }
    getModificationsSync(params, activateAll) {
        return this.getStrategy().getModificationsSync(params, activateAll);
    }
    getModificationInfo(key) {
        return this.getStrategy().getModificationInfo(key);
    }
    getModificationInfoSync(key) {
        return this.getStrategy().getModificationInfoSync(key);
    }
    async synchronizeModifications() {
        await this.getStrategy().lookupVisitor();
        await this.getStrategy().synchronizeModifications();
        await this.getStrategy().cacheVisitor();
    }
    activateModification(key) {
        return this.getStrategy().activateModification(key);
    }
    activateModifications(params) {
        return this.getStrategy().activateModifications(params);
    }
    sendHit(hit) {
        return this.getStrategy().sendHit(hit);
    }
    sendHits(hits) {
        return this.getStrategy().sendHits(hits);
    }
    getAllModifications(activate = false) {
        return this.getStrategy().getAllModifications(activate);
    }
    getAllFlagsData(activate = false) {
        return this.getStrategy().getAllFlagsData(activate);
    }
    getModificationsForCampaign(campaignId, activate = false) {
        return this.getStrategy().getModificationsForCampaign(campaignId, activate);
    }
    getFlatsDataForCampaign(campaignId, activate = false) {
        return this.getStrategy().getFlatsDataForCampaign(campaignId, activate);
    }
    authenticate(visitorId) {
        this.getStrategy().authenticate(visitorId);
        this.updateCache();
    }
    unauthenticate() {
        this.getStrategy().unauthenticate();
        this.updateCache();
    }
    async fetchFlags() {
        await this.getStrategy().lookupVisitor();
        await this.getStrategy().fetchFlags();
        await this.getStrategy().cacheVisitor();
    }
    visitorExposed(param) {
        return this.getStrategy().visitorExposed(param);
    }
    getFlagValue(param) {
        return this.getStrategy().getFlagValue(param);
    }
    getFlagMetadata(param) {
        return this.getStrategy().getFlagMetadata(param);
    }
}
