export const VISITOR_PREFIX = 'FS_VISITOR_CACHE_';
export class DefaultVisitorCache {
    cacheVisitor(visitorId, data) {
        localStorage.setItem(VISITOR_PREFIX + visitorId, JSON.stringify(data));
        return Promise.resolve();
    }
    lookupVisitor(visitorId) {
        const data = localStorage.getItem(VISITOR_PREFIX + visitorId);
        return Promise.resolve(data ? JSON.parse(data) : null);
    }
    flushVisitor(visitorId) {
        localStorage.removeItem(VISITOR_PREFIX + visitorId);
        return Promise.resolve();
    }
}
