import { PREDEFINED_CONTEXT_LOADED, PROCESS_NEW_VISITOR, VISITOR_CREATED, VISITOR_ID_GENERATED, VISITOR_PROFILE_LOADED } from './../enum/FlagshipConstant';
import { DecisionMode } from '../config/index';
import { FlagshipStatus, SDK_INFO, VISITOR_ID_ERROR } from '../enum/index';
import { logDebugSprintf, logError, uuidV4 } from '../utils/utils';
import { DefaultStrategy } from './DefaultStrategy';
import { EventEmitter } from '../depsNode.native';
import { NotReadyStrategy } from './NotReadyStrategy';
import { PanicStrategy } from './PanicStrategy';
import { NoConsentStrategy } from './NoConsentStrategy';
import { cacheVisitor } from './VisitorCache';
import { MurmurHash } from '../utils/MurmurHash';
import { FlagSynchStatus } from '../enum/FlagSynchStatus';
export class VisitorAbstract extends EventEmitter {
    _visitorId;
    _context;
    _flags;
    _configManager;
    _campaigns;
    _hasConsented;
    _anonymousId;
    deDuplicationCache;
    _isCleaningDeDuplicationCache;
    visitorCache;
    _instanceId;
    _traffic;
    _sdkInitialData;
    _consentHitTroubleshooting;
    _segmentHitTroubleshooting;
    get segmentHitTroubleshooting() {
        return this._segmentHitTroubleshooting;
    }
    set segmentHitTroubleshooting(v) {
        this._segmentHitTroubleshooting = v;
    }
    get consentHitTroubleshooting() {
        return this._consentHitTroubleshooting;
    }
    set consentHitTroubleshooting(v) {
        this._consentHitTroubleshooting = v;
    }
    get sdkInitialData() {
        return this._sdkInitialData;
    }
    static SdkStatus;
    getSdkStatus() {
        return VisitorAbstract.SdkStatus;
    }
    _flagSynchStatus;
    lastFetchFlagsTimestamp = 0;
    isFlagFetching = false;
    _visitorCacheStatus;
    get visitorCacheStatus() {
        return this._visitorCacheStatus;
    }
    set visitorCacheStatus(v) {
        this._visitorCacheStatus = v;
    }
    get flagSynchStatus() {
        return this._flagSynchStatus;
    }
    set flagSynchStatus(v) {
        this._flagSynchStatus = v;
    }
    constructor(param) {
        const { visitorId, configManager, context, isAuthenticated, hasConsented, initialModifications, initialFlagsData, initialCampaigns, monitoringData } = param;
        super();
        this._sdkInitialData = monitoringData;
        this._instanceId = uuidV4();
        this._isCleaningDeDuplicationCache = false;
        this.deDuplicationCache = {};
        this._context = {};
        this._configManager = configManager;
        const visitorCache = this.config.enableClientCache ? cacheVisitor.loadVisitorProfile() : null;
        if (visitorCache) {
            logDebugSprintf(this.config, PROCESS_NEW_VISITOR, VISITOR_PROFILE_LOADED, visitorCache);
        }
        this.visitorId = visitorId || (!isAuthenticated && visitorCache?.anonymousId ? visitorCache?.anonymousId : visitorCache?.visitorId) || this.generateVisitorId();
        this.campaigns = [];
        this._anonymousId = isAuthenticated && visitorCache?.anonymousId ? visitorCache?.anonymousId : null;
        if (!this._anonymousId && isAuthenticated && (this.config.decisionMode === DecisionMode.DECISION_API || this.config.decisionMode === DecisionMode.API)) {
            this._anonymousId = uuidV4();
        }
        this.setConsent(hasConsented ?? true);
        this.updateContext(context);
        this.loadPredefinedContext();
        logDebugSprintf(this.config, PROCESS_NEW_VISITOR, PREDEFINED_CONTEXT_LOADED, {
            fs_client: SDK_INFO.name,
            fs_version: SDK_INFO.version,
            fs_users: this.visitorId
        });
        this.updateCache();
        this.setInitialFlags(initialFlagsData || initialModifications);
        this.setInitializeCampaigns(initialCampaigns, !!initialModifications);
        this._flagSynchStatus = FlagSynchStatus.CREATED;
        logDebugSprintf(this.config, PROCESS_NEW_VISITOR, VISITOR_CREATED, this.visitorId, this.context, !!isAuthenticated, !!this.hasConsented);
    }
    get traffic() {
        return this._traffic;
    }
    set traffic(v) {
        this._traffic = v;
    }
    get instanceId() {
        return this._instanceId;
    }
    generateVisitorId() {
        const visitorId = uuidV4();
        logDebugSprintf(this.config, PROCESS_NEW_VISITOR, VISITOR_ID_GENERATED, visitorId);
        return visitorId;
    }
    clearDeDuplicationCache(deDuplicationTime) {
        if (this._isCleaningDeDuplicationCache) {
            return;
        }
        this._isCleaningDeDuplicationCache = true;
        const entries = Object.entries(this.deDuplicationCache);
        for (const [key, value] of entries) {
            if ((Date.now() - value) > (deDuplicationTime * 1000)) {
                delete this.deDuplicationCache[key];
            }
        }
        this._isCleaningDeDuplicationCache = false;
    }
    getModificationsArray() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return Array.from(this._flags, ([_, item]) => item);
    }
    getFlagsDataArray() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return Array.from(this._flags, ([_, item]) => item);
    }
    setInitialFlags(modifications) {
        this._flags = new Map();
        if (!modifications || (!(modifications instanceof Map) && !Array.isArray(modifications))) {
            return;
        }
        modifications.forEach((item) => {
            this._flags.set(item.key, item);
        });
    }
    setInitializeCampaigns(campaigns, hasModifications) {
        if (campaigns && Array.isArray(campaigns) && !hasModifications) {
            this.getStrategy().updateCampaigns(campaigns);
        }
    }
    updateCache() {
        const visitorProfile = {
            visitorId: this.visitorId,
            anonymousId: this.anonymousId
        };
        cacheVisitor.saveVisitorProfile(visitorProfile);
    }
    loadPredefinedContext() {
        this.context.fs_client = SDK_INFO.name;
        this.context.fs_version = SDK_INFO.version;
        this.context.fs_users = this.visitorId;
    }
    get visitorId() {
        return this._visitorId;
    }
    set visitorId(v) {
        if (!v || typeof v !== 'string') {
            logError(this.config, VISITOR_ID_ERROR, 'VISITOR ID');
            return;
        }
        this._visitorId = v;
        this.loadPredefinedContext();
        this.visitorCache = undefined;
    }
    /**
     * Return True or False if the visitor has consented for protected data usage.
     * @return bool
     */
    get hasConsented() {
        return this._hasConsented;
    }
    set hasConsented(v) {
        this._hasConsented = v;
    }
    /**
      * Set if visitor has consented for protected data usage.
      * @param {boolean} hasConsented True if the visitor has consented false otherwise.
      */
    setConsent(hasConsented) {
        this.hasConsented = hasConsented;
        this.getStrategy().setConsent(hasConsented);
    }
    get context() {
        return this._context;
    }
    /**
    * Clear the current context and set a new context value
    */
    set context(v) {
        this._context = {};
        this.updateContext(v);
    }
    get flagsData() {
        return this._flags;
    }
    set flagsData(v) {
        this._flags = v;
    }
    get modifications() {
        return this._flags;
    }
    set modifications(v) {
        this._flags = v;
    }
    get configManager() {
        return this._configManager;
    }
    get config() {
        return this.configManager.config;
    }
    get campaigns() {
        return this._campaigns;
    }
    set campaigns(v) {
        this._campaigns = v;
    }
    get anonymousId() {
        return this._anonymousId;
    }
    set anonymousId(v) {
        this._anonymousId = v;
    }
    getStrategy() {
        let strategy;
        const params = {
            visitor: this,
            murmurHash: new MurmurHash()
        };
        const status = this.getSdkStatus();
        if (status === undefined || status === FlagshipStatus.NOT_INITIALIZED) {
            strategy = new NotReadyStrategy(params);
        }
        else if (status === FlagshipStatus.READY_PANIC_ON) {
            strategy = new PanicStrategy(params);
        }
        else if (!this.hasConsented) {
            strategy = new NoConsentStrategy(params);
        }
        else {
            strategy = new DefaultStrategy(params);
        }
        return strategy;
    }
}
