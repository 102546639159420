import { FLAGSHIP_SDK, LogLevel } from '../enum/index';
export class FlagshipLogManager {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    consoleError;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    consoleWarn;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    consoleDebug;
    constructor() {
        this.consoleError = console.error ?? console.log;
        this.consoleWarn = console.warn ?? console.log;
        this.consoleDebug = console.debug ?? console.log;
    }
    emergency(message, tag) {
        this.consoleError(this.formatOutput(LogLevel.EMERGENCY, message, tag));
    }
    alert(message, tag) {
        this.consoleError(this.formatOutput(LogLevel.ALERT, message, tag));
    }
    critical(message, tag) {
        this.consoleError(this.formatOutput(LogLevel.CRITICAL, message, tag));
    }
    error(message, tag) {
        this.consoleError(this.formatOutput(LogLevel.ERROR, message, tag));
    }
    warning(message, tag) {
        this.consoleWarn(this.formatOutput(LogLevel.WARNING, message, tag));
    }
    notice(message, tag) {
        this.log(LogLevel.NOTICE, message, tag);
    }
    info(message, tag) {
        console.info(this.formatOutput(LogLevel.INFO, message, tag));
    }
    debug(message, tag) {
        this.consoleDebug(this.formatOutput(LogLevel.DEBUG, message, tag));
    }
    log(level, message, tag) {
        console.log(this.formatOutput(level, message, tag));
    }
    formatOutput(level, message, tag) {
        const now = new Date(Date.now());
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const getTwoDigit = (value) => {
            return value.toString().length === 1 ? `0${value}` : value;
        };
        return `[${getTwoDigit(now.getFullYear())}-${getTwoDigit(now.getMonth() + 1)}-${getTwoDigit(now.getDate())} ${getTwoDigit(now.getHours())}:${getTwoDigit(now.getMinutes())}:${getTwoDigit(now.getSeconds())}.${getTwoDigit(now.getMilliseconds())}] [${FLAGSHIP_SDK}] [${LogLevel[level]}] [${tag}] : ${message}`;
    }
}
