export var LogLevel;
(function (LogLevel) {
    /**
       * NONE = 0: Logging will be disabled.
       */
    LogLevel[LogLevel["NONE"] = 0] = "NONE";
    /**
       * EMERGENCY = 1: Only emergencies will be logged.
       */
    LogLevel[LogLevel["EMERGENCY"] = 1] = "EMERGENCY";
    /**
       * ALERT = 2: Only alerts and above will be logged.
       */
    LogLevel[LogLevel["ALERT"] = 2] = "ALERT";
    /**
       * CRITICAL = 3: Only critical and above will be logged.
       */
    LogLevel[LogLevel["CRITICAL"] = 3] = "CRITICAL";
    /**
       * ERROR = 4: Only errors and above will be logged.
       */
    LogLevel[LogLevel["ERROR"] = 4] = "ERROR";
    /**
       * WARNING = 5: Only warnings and above will be logged.
       */
    LogLevel[LogLevel["WARNING"] = 5] = "WARNING";
    /**
       * NOTICE = 6: Only notices and above will be logged.
       */
    LogLevel[LogLevel["NOTICE"] = 6] = "NOTICE";
    /**
       * INFO = 7: Only info logs and above will be logged.
       */
    LogLevel[LogLevel["INFO"] = 7] = "INFO";
    /**
       * DEBUG = 8: Only debug logs and above will be logged.
       */
    LogLevel[LogLevel["DEBUG"] = 8] = "DEBUG";
    /**
       * ALL = 9: All logs will be logged.
       */
    LogLevel[LogLevel["ALL"] = 9] = "ALL";
})(LogLevel || (LogLevel = {}));
