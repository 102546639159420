import { FLAG_METADATA, NO_FLAG_METADATA } from '../enum/index';
import { hasSameType, logDebugSprintf } from '../utils/utils';
import { FlagMetadata } from './FlagMetadata';
export class Flag {
    _visitor;
    _key;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _defaultValue;
    constructor(param) {
        const { key, visitor, defaultValue } = param;
        this._key = key;
        this._visitor = visitor;
        this._defaultValue = defaultValue;
    }
    exists() {
        const flagDTO = this._visitor.flagsData.get(this._key);
        return !!(flagDTO?.campaignId && flagDTO.variationId && flagDTO.variationGroupId);
    }
    get metadata() {
        const flagDTO = this._visitor.flagsData.get(this._key);
        const metadata = new FlagMetadata({
            campaignId: flagDTO?.campaignId || '',
            campaignName: flagDTO?.campaignName || '',
            variationGroupId: flagDTO?.variationGroupId || '',
            variationGroupName: flagDTO?.variationGroupName || '',
            variationId: flagDTO?.variationId || '',
            variationName: flagDTO?.variationName || '',
            isReference: !!flagDTO?.isReference,
            campaignType: flagDTO?.campaignType || '',
            slug: flagDTO?.slug
        });
        if (!flagDTO) {
            logDebugSprintf(this._visitor.config, FLAG_METADATA, NO_FLAG_METADATA, this._visitor.visitorId, this._key, metadata);
            return metadata;
        }
        return this._visitor.getFlagMetadata({
            metadata,
            hasSameType: flagDTO.value === null || this._defaultValue === null || this._defaultValue === undefined || hasSameType(flagDTO.value, this._defaultValue),
            key: flagDTO.key
        });
    }
    userExposed() {
        return this.visitorExposed();
    }
    visitorExposed() {
        const flagDTO = this._visitor.flagsData.get(this._key);
        return this._visitor.visitorExposed({ key: this._key, flag: flagDTO, defaultValue: this._defaultValue });
    }
    getValue(userExposed = true) {
        const flagDTO = this._visitor.flagsData.get(this._key);
        return this._visitor.getFlagValue({
            key: this._key,
            defaultValue: this._defaultValue,
            flag: flagDTO,
            userExposed
        });
    }
}
