import { CacheStrategy, FlagshipStatus, LogLevel } from '../enum/index';
import { CUSTOMER_ENV_ID_API_ITEM, DS_API_ITEM, SDK_APP, SDK_INFO, T_API_ITEM, VISITOR_ID_API_ITEM } from '../enum/FlagshipConstant';
import { HitAbstract } from './HitAbstract';
import { BatchTriggeredBy } from '../enum/BatchTriggeredBy';
export const ERROR_MESSAGE = 'event category and event action are required';
export class Diagnostic extends HitAbstract {
    _logVersion;
    _logLevel;
    _accountId;
    _envId;
    _timestamp;
    _label;
    _stackType;
    _stackName;
    _stackVersion;
    _stackOriginName;
    _stackOriginVersion;
    _sdkStatus;
    _sdkConfigMode;
    _sdkConfigCustomLogManager;
    _sdkConfigCustomCacheManager;
    _sdkConfigStatusListener;
    _sdkConfigTimeout;
    _sdkConfigPollingTime;
    _sdkConfigTrackingManagerConfigStrategy;
    _sdkConfigTrackingManagerConfigBatchIntervals;
    _sdkConfigTrackingManagerConfigBatchLength;
    _httpRequestUrl;
    _httpRequestMethod;
    _httpRequestHeaders;
    _httpRequestBody;
    _httpResponseUrl;
    _httpResponseMethod;
    _httpResponseHeaders;
    _httpResponseBody;
    _visitorStatus;
    _visitorInstanceType;
    _visitorContext;
    _visitorConsent;
    _visitorAssignmentHistory;
    _visitorFlags;
    _visitorIsAuthenticated;
    _flagKey;
    _flagValue;
    _flagDefault;
    _flagMetadataCampaignId;
    _flagMetadataVariationGroupId;
    _flagMetadataVariationId;
    _flagMetadataCampaignSlug;
    _flagMetadataCampaignType;
    _sdkConfigFetchNow;
    _sdkConfigEnableClientCache;
    _sdkConfigInitialBucketing;
    _sdkConfigDecisionApiUrl;
    _sdkConfigHitDeduplicationTime;
    _visitorInitialCampaigns;
    _visitorInitialFlagsData;
    _visitorCampaign;
    _httRequestTime;
    _hitContent;
    _httpInstanceId;
    _lastInitializationTimestamp;
    _lastBucketingTimestamp;
    _batchTriggeredBy;
    _visitorCampaignFromCache;
    _timeZone;
    _flagMetadataCampaignIsReference;
    _contextKey;
    _contextValue;
    _sdkBucketingFile;
    _flagMetadataCampaignName;
    _flagMetadataVariationGroupName;
    _flagMetadataVariationName;
    _sdkConfigUsingCustomHitCache;
    _sdkConfigUsingCustomVisitorCache;
    _sdkConfigUsingOnVisitorExposed;
    _sdkConfigFetchThirdPartyData;
    _sdkConfigFetchFlagsBufferingTime;
    _sdkConfigNextFetchConfig;
    _sdkConfigDisableDeveloperUsageTracking;
    _sdkConfigDisableCache;
    _visitorSessionId;
    _traffic;
    _flagshipInstanceId;
    get traffic() {
        return this._traffic;
    }
    set traffic(v) {
        this._traffic = v;
    }
    get flagshipInstanceId() {
        return this._flagshipInstanceId;
    }
    set flagshipInstanceId(v) {
        this._flagshipInstanceId = v;
    }
    get visitorSessionId() {
        return this._visitorSessionId;
    }
    set visitorSessionId(v) {
        this._visitorSessionId = v;
    }
    get sdkConfigDisableCache() {
        return this._sdkConfigDisableCache;
    }
    set sdkConfigDisableCache(v) {
        this._sdkConfigDisableCache = v;
    }
    get sdkConfigDisableDeveloperUsageTracking() {
        return this._sdkConfigDisableDeveloperUsageTracking;
    }
    set sdkConfigDisableDeveloperUsageTracking(v) {
        this._sdkConfigDisableDeveloperUsageTracking = v;
    }
    get sdkConfigNextFetchConfig() {
        return this._sdkConfigNextFetchConfig;
    }
    set sdkConfigNextFetchConfig(v) {
        this._sdkConfigNextFetchConfig = v;
    }
    get sdkConfigFetchFlagsBufferingTime() {
        return this._sdkConfigFetchFlagsBufferingTime;
    }
    set sdkConfigFetchFlagsBufferingTime(v) {
        this._sdkConfigFetchFlagsBufferingTime = v;
    }
    get sdkConfigFetchThirdPartyData() {
        return this._sdkConfigFetchThirdPartyData;
    }
    set sdkConfigFetchThirdPartyData(v) {
        this._sdkConfigFetchThirdPartyData = v;
    }
    get sdkConfigUsingOnVisitorExposed() {
        return this._sdkConfigUsingOnVisitorExposed;
    }
    set sdkConfigUsingOnVisitorExposed(v) {
        this._sdkConfigUsingOnVisitorExposed = v;
    }
    get sdkConfigUsingCustomVisitorCache() {
        return this._sdkConfigUsingCustomVisitorCache;
    }
    set sdkConfigUsingCustomVisitorCache(v) {
        this._sdkConfigUsingCustomVisitorCache = v;
    }
    get sdkConfigUsingCustomHitCache() {
        return this._sdkConfigUsingCustomHitCache;
    }
    set sdkConfigUsingCustomHitCache(v) {
        this._sdkConfigUsingCustomHitCache = v;
    }
    get flagMetadataVariationName() {
        return this._flagMetadataVariationName;
    }
    set flagMetadataVariationName(v) {
        this._flagMetadataVariationName = v;
    }
    get flagMetadataVariationGroupName() {
        return this._flagMetadataVariationGroupName;
    }
    set flagMetadataVariationGroupName(v) {
        this._flagMetadataVariationGroupName = v;
    }
    get flagMetadataCampaignName() {
        return this._flagMetadataCampaignName;
    }
    set flagMetadataCampaignName(v) {
        this._flagMetadataCampaignName = v;
    }
    get sdkBucketingFile() {
        return this._sdkBucketingFile;
    }
    set sdkBucketingFile(v) {
        this._sdkBucketingFile = v;
    }
    get contextValue() {
        return this._contextValue;
    }
    set contextValue(v) {
        this._contextValue = v;
    }
    get contextKey() {
        return this._contextKey;
    }
    set contextKey(v) {
        this._contextKey = v;
    }
    get flagMetadataCampaignIsReference() {
        return this._flagMetadataCampaignIsReference;
    }
    set flagMetadataCampaignIsReference(v) {
        this._flagMetadataCampaignIsReference = v;
    }
    get timeZone() {
        return this._timeZone;
    }
    set timeZone(v) {
        this._timeZone = v;
    }
    get visitorCampaignFromCache() {
        return this._visitorCampaignFromCache;
    }
    set visitorCampaignFromCache(v) {
        this._visitorCampaignFromCache = v;
    }
    get batchTriggeredBy() {
        return this._batchTriggeredBy;
    }
    set batchTriggeredBy(v) {
        this._batchTriggeredBy = v;
    }
    get lastBucketingTimestamp() {
        return this._lastBucketingTimestamp;
    }
    set lastBucketingTimestamp(v) {
        this._lastBucketingTimestamp = v;
    }
    get lastInitializationTimestamp() {
        return this._lastInitializationTimestamp;
    }
    set lastInitializationTimestamp(v) {
        this._lastInitializationTimestamp = v;
    }
    get hitContent() {
        return this._hitContent;
    }
    set hitContent(v) {
        this._hitContent = v;
    }
    get httpResponseTime() {
        return this._httRequestTime;
    }
    set httpResponseTime(v) {
        this._httRequestTime = v;
    }
    get visitorCampaigns() {
        return this._visitorCampaign;
    }
    set visitorCampaigns(v) {
        this._visitorCampaign = v;
    }
    get visitorInitialFlagsData() {
        return this._visitorInitialFlagsData;
    }
    set visitorInitialFlagsData(v) {
        this._visitorInitialFlagsData = v;
    }
    get visitorInitialCampaigns() {
        return this._visitorInitialCampaigns;
    }
    set visitorInitialCampaigns(v) {
        this._visitorInitialCampaigns = v;
    }
    get sdkConfigHitDeduplicationTime() {
        return this._sdkConfigHitDeduplicationTime;
    }
    set sdkConfigHitDeduplicationTime(v) {
        this._sdkConfigHitDeduplicationTime = v;
    }
    get sdkConfigDecisionApiUrl() {
        return this._sdkConfigDecisionApiUrl;
    }
    set sdkConfigDecisionApiUrl(v) {
        this._sdkConfigDecisionApiUrl = v;
    }
    get sdkConfigInitialBucketing() {
        return this._sdkConfigInitialBucketing;
    }
    set sdkConfigInitialBucketing(v) {
        this._sdkConfigInitialBucketing = v;
    }
    get sdkConfigEnableClientCache() {
        return this._sdkConfigEnableClientCache;
    }
    set sdkConfigEnableClientCache(v) {
        this._sdkConfigEnableClientCache = v;
    }
    get sdkConfigFetchNow() {
        return this._sdkConfigFetchNow;
    }
    set sdkConfigFetchNow(v) {
        this._sdkConfigFetchNow = v;
    }
    get flagMetadataCampaignType() {
        return this._flagMetadataCampaignType;
    }
    set flagMetadataCampaignType(v) {
        this._flagMetadataCampaignType = v;
    }
    get flagMetadataCampaignSlug() {
        return this._flagMetadataCampaignSlug;
    }
    set flagMetadataCampaignSlug(v) {
        this._flagMetadataCampaignSlug = v;
    }
    get flagMetadataVariationId() {
        return this._flagMetadataVariationId;
    }
    set flagMetadataVariationId(v) {
        this._flagMetadataVariationId = v;
    }
    get flagMetadataVariationGroupId() {
        return this._flagMetadataVariationGroupId;
    }
    set flagMetadataVariationGroupId(v) {
        this._flagMetadataVariationGroupId = v;
    }
    get flagMetadataCampaignId() {
        return this._flagMetadataCampaignId;
    }
    set flagMetadataCampaignId(v) {
        this._flagMetadataCampaignId = v;
    }
    get flagDefault() {
        return this._flagDefault;
    }
    set flagDefault(v) {
        this._flagDefault = v;
    }
    get flagValue() {
        return this._flagValue;
    }
    set flagValue(v) {
        this._flagValue = v;
    }
    get flagKey() {
        return this._flagKey;
    }
    set flagKey(v) {
        this._flagKey = v;
    }
    get visitorIsAuthenticated() {
        return this._visitorIsAuthenticated;
    }
    set visitorIsAuthenticated(v) {
        this._visitorIsAuthenticated = v;
    }
    get visitorFlags() {
        return this._visitorFlags;
    }
    set visitorFlags(v) {
        this._visitorFlags = v;
    }
    get visitorAssignmentHistory() {
        return this._visitorAssignmentHistory;
    }
    set visitorAssignmentHistory(v) {
        this._visitorAssignmentHistory = v;
    }
    get visitorConsent() {
        return this._visitorConsent;
    }
    set visitorConsent(v) {
        this._visitorConsent = v;
    }
    get visitorContext() {
        return this._visitorContext;
    }
    set visitorContext(v) {
        this._visitorContext = v;
    }
    get visitorInstanceType() {
        return this._visitorInstanceType;
    }
    set visitorInstanceType(v) {
        this._visitorInstanceType = v;
    }
    get visitorStatus() {
        return this._visitorStatus;
    }
    set visitorStatus(v) {
        this._visitorStatus = v;
    }
    get httpResponseBody() {
        return this._httpResponseBody;
    }
    set httpResponseBody(v) {
        this._httpResponseBody = v;
    }
    _httpResponseCode;
    get httpResponseCode() {
        return this._httpResponseCode;
    }
    set httpResponseCode(v) {
        this._httpResponseCode = v;
    }
    get httpResponseHeaders() {
        return this._httpResponseHeaders;
    }
    set httpResponseHeaders(v) {
        this._httpResponseHeaders = v;
    }
    get httpResponseMethod() {
        return this._httpResponseMethod;
    }
    set httpResponseMethod(v) {
        this._httpResponseMethod = v;
    }
    get httpResponseUrl() {
        return this._httpResponseUrl;
    }
    set httpResponseUrl(v) {
        this._httpResponseUrl = v;
    }
    get httpRequestBody() {
        return this._httpRequestBody;
    }
    set httpRequestBody(v) {
        this._httpRequestBody = v;
    }
    get httpRequestHeaders() {
        return this._httpRequestHeaders;
    }
    set httpRequestHeaders(v) {
        this._httpRequestHeaders = v;
    }
    get httpRequestMethod() {
        return this._httpRequestMethod;
    }
    set httpRequestMethod(v) {
        this._httpRequestMethod = v;
    }
    get httpRequestUrl() {
        return this._httpRequestUrl;
    }
    set httpRequestUrl(v) {
        this._httpRequestUrl = v;
    }
    get sdkConfigTrackingManagerConfigPoolMaxSize() {
        return this._sdkConfigTrackingManagerConfigBatchLength;
    }
    set sdkConfigTrackingManagerConfigPoolMaxSize(v) {
        this._sdkConfigTrackingManagerConfigBatchLength = v;
    }
    get sdkConfigTrackingManagerConfigBatchIntervals() {
        return this._sdkConfigTrackingManagerConfigBatchIntervals;
    }
    set sdkConfigTrackingManagerConfigBatchIntervals(v) {
        this._sdkConfigTrackingManagerConfigBatchIntervals = v;
    }
    get sdkConfigTrackingManagerConfigStrategy() {
        return this._sdkConfigTrackingManagerConfigStrategy;
    }
    set sdkConfigTrackingManagerConfigStrategy(v) {
        this._sdkConfigTrackingManagerConfigStrategy = v;
    }
    get sdkConfigPollingInterval() {
        return this._sdkConfigPollingTime;
    }
    set sdkConfigPollingInterval(v) {
        this._sdkConfigPollingTime = v;
    }
    get sdkConfigTimeout() {
        return this._sdkConfigTimeout;
    }
    set sdkConfigTimeout(v) {
        this._sdkConfigTimeout = v;
    }
    get sdkConfigStatusListener() {
        return this._sdkConfigStatusListener;
    }
    set sdkConfigStatusListener(v) {
        this._sdkConfigStatusListener = v;
    }
    get sdkConfigCustomCacheManager() {
        return this._sdkConfigCustomCacheManager;
    }
    set sdkConfigCustomCacheManager(v) {
        this._sdkConfigCustomCacheManager = v;
    }
    get sdkConfigCustomLogManager() {
        return this._sdkConfigCustomLogManager;
    }
    set sdkConfigCustomLogManager(v) {
        this._sdkConfigCustomLogManager = v;
    }
    get sdkConfigMode() {
        return this._sdkConfigMode;
    }
    set sdkConfigMode(v) {
        this._sdkConfigMode = v;
    }
    get sdkStatus() {
        return this._sdkStatus;
    }
    set sdkStatus(v) {
        this._sdkStatus = v;
    }
    get stackOriginVersion() {
        return this._stackOriginVersion;
    }
    set stackOriginVersion(v) {
        this._stackOriginVersion = v;
    }
    get stackOriginName() {
        return this._stackOriginName;
    }
    set stackOriginName(v) {
        this._stackOriginName = v;
    }
    get stackVersion() {
        return this._stackVersion;
    }
    set stackVersion(v) {
        this._stackVersion = v;
    }
    get stackName() {
        return this._stackName;
    }
    set stackName(v) {
        this._stackName = v;
    }
    get stackType() {
        return this._stackType;
    }
    set stackType(v) {
        this._stackType = v;
    }
    get label() {
        return this._label;
    }
    set label(v) {
        this._label = v;
    }
    get timestamp() {
        return this._timestamp;
    }
    set timestamp(v) {
        this._timestamp = v;
    }
    get envId() {
        return this._envId;
    }
    set envId(v) {
        this._envId = v;
    }
    get accountId() {
        return this._accountId;
    }
    set accountId(v) {
        this._accountId = v;
    }
    get logLevel() {
        return this._logLevel;
    }
    set logLevel(v) {
        this._logLevel = v;
    }
    get version() {
        return this._logVersion;
    }
    set version(v) {
        this._logVersion = v;
    }
    constructor(param) {
        super({
            type: param.type,
            userIp: param.userIp,
            screenResolution: param.screenResolution,
            locale: param.locale,
            sessionNumber: param.sessionNumber,
            visitorId: param.visitorId,
            anonymousId: param.anonymousId
        });
        const { version: logVersion, logLevel, accountId, envId, timestamp, label, stackType, stackName, stackVersion, stackOriginName, stackOriginVersion, sdkStatus, sdkConfigMode, sdkConfigCustomLogManager, sdkConfigCustomCacheManager, sdkConfigStatusListener, sdkConfigTimeout, sdkConfigPollingInterval, sdkConfigTrackingManagerConfigStrategy, sdkConfigTrackingManagerConfigBatchIntervals, sdkConfigTrackingManagerConfigPoolMaxSize, httpRequestUrl, httpRequestMethod, httpRequestHeaders, httpRequestBody, httpResponseTime, httpResponseUrl, httpResponseMethod, httpResponseHeaders, httpResponseCode, httpResponseBody, visitorStatus, visitorInstanceType, visitorContext, visitorConsent, visitorAssignmentHistory, visitorFlags, visitorIsAuthenticated, config, flagKey, flagValue, flagDefault, flagMetadataCampaignId, flagMetadataVariationGroupId, flagMetadataVariationId, flagMetadataCampaignSlug, flagMetadataCampaignType, sdkConfigFetchNow, sdkConfigEnableClientCache, sdkConfigInitialBucketing, sdkConfigDecisionApiUrl, sdkConfigHitDeduplicationTime, flagshipInstanceId, hitContent, traffic, lastInitializationTimestamp, lastBucketingTimestamp, batchTriggeredBy, visitorCampaigns, visitorCampaignFromCache, visitorInitialCampaigns, visitorInitialFlagsData, flagMetadataCampaignIsReference, contextKey, contextValue, sdkBucketingFile, flagMetadataCampaignName, flagMetadataVariationGroupName, flagMetadataVariationName, sdkConfigUsingCustomHitCache, sdkConfigUsingCustomVisitorCache, sdkConfigUsingOnVisitorExposed, sdkConfigFetchThirdPartyData, sdkConfigFetchFlagsBufferingTime, sdkConfigDisableDeveloperUsageTracking, sdkConfigNextFetchConfig, sdkConfigDisableCache, visitorSessionId } = param;
        this.visitorSessionId = visitorSessionId;
        this.sdkConfigDisableCache = sdkConfigDisableCache;
        this.sdkConfigDisableDeveloperUsageTracking = sdkConfigDisableDeveloperUsageTracking;
        this.sdkConfigNextFetchConfig = sdkConfigNextFetchConfig;
        this.sdkConfigFetchFlagsBufferingTime = sdkConfigFetchFlagsBufferingTime;
        this.sdkConfigFetchThirdPartyData = sdkConfigFetchThirdPartyData;
        this.sdkConfigUsingCustomHitCache = sdkConfigUsingCustomHitCache;
        this.sdkConfigUsingCustomVisitorCache = sdkConfigUsingCustomVisitorCache;
        this.sdkConfigUsingOnVisitorExposed = sdkConfigUsingOnVisitorExposed;
        this.flagMetadataCampaignName = flagMetadataCampaignName;
        this.flagMetadataVariationGroupName = flagMetadataVariationGroupName;
        this.flagMetadataVariationName = flagMetadataVariationName;
        this.traffic = traffic;
        this.sdkBucketingFile = sdkBucketingFile;
        this.contextKey = contextKey;
        this.contextValue = contextValue;
        this.lastBucketingTimestamp = lastBucketingTimestamp;
        this.lastInitializationTimestamp = lastInitializationTimestamp;
        this.batchTriggeredBy = batchTriggeredBy;
        this.visitorCampaigns = visitorCampaigns;
        this.visitorAssignmentHistory = visitorAssignmentHistory;
        this.visitorInitialCampaigns = visitorInitialCampaigns;
        this.visitorInitialFlagsData = visitorInitialFlagsData;
        this.visitorCampaignFromCache = visitorCampaignFromCache;
        this.flagMetadataCampaignIsReference = flagMetadataCampaignIsReference;
        this.config = config;
        this.version = logVersion || '1';
        this.logLevel = logLevel;
        this.flagshipInstanceId = flagshipInstanceId;
        this.accountId = accountId;
        this.envId = envId || config.envId;
        this.timestamp = timestamp || new Date(Date.now()).toISOString();
        this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.label = label;
        this.stackType = stackType || 'SDK';
        this.stackName = stackName || SDK_INFO.name;
        this.stackVersion = stackVersion || SDK_INFO.version;
        this.stackOriginName = stackOriginName;
        this.stackOriginVersion = stackOriginVersion;
        this.sdkStatus = sdkStatus;
        this.sdkConfigMode = sdkConfigMode;
        this.sdkConfigCustomLogManager = sdkConfigCustomLogManager;
        this.sdkConfigCustomCacheManager = sdkConfigCustomCacheManager;
        this.sdkConfigStatusListener = sdkConfigStatusListener;
        this.sdkConfigTimeout = sdkConfigTimeout;
        this.sdkConfigPollingInterval = sdkConfigPollingInterval;
        this.sdkConfigTrackingManagerConfigStrategy = sdkConfigTrackingManagerConfigStrategy;
        this.sdkConfigTrackingManagerConfigBatchIntervals = sdkConfigTrackingManagerConfigBatchIntervals;
        this.sdkConfigTrackingManagerConfigPoolMaxSize = sdkConfigTrackingManagerConfigPoolMaxSize;
        this.sdkConfigFetchNow = sdkConfigFetchNow;
        this.sdkConfigEnableClientCache = sdkConfigEnableClientCache;
        this.sdkConfigInitialBucketing = sdkConfigInitialBucketing;
        this.sdkConfigDecisionApiUrl = sdkConfigDecisionApiUrl;
        this.sdkConfigHitDeduplicationTime = sdkConfigHitDeduplicationTime;
        this.httpRequestUrl = httpRequestUrl;
        this.httpRequestMethod = httpRequestMethod;
        this.httpRequestHeaders = httpRequestHeaders;
        this.httpRequestBody = httpRequestBody;
        this.httpResponseUrl = httpResponseUrl;
        this.httpResponseMethod = httpResponseMethod;
        this.httpResponseHeaders = httpResponseHeaders;
        this.httpResponseCode = httpResponseCode;
        this.httpResponseBody = httpResponseBody;
        this.httpResponseTime = httpResponseTime;
        this.visitorStatus = visitorStatus;
        this.visitorInstanceType = visitorInstanceType;
        this.visitorContext = visitorContext;
        this.visitorConsent = visitorConsent;
        this.visitorAssignmentHistory = visitorAssignmentHistory;
        this.visitorFlags = visitorFlags;
        this.visitorIsAuthenticated = visitorIsAuthenticated;
        this.flagKey = flagKey;
        this.flagValue = flagValue;
        this.flagDefault = flagDefault;
        this.flagMetadataCampaignId = flagMetadataCampaignId;
        this.flagMetadataVariationGroupId = flagMetadataVariationGroupId;
        this.flagMetadataVariationId = flagMetadataVariationId;
        this.flagMetadataCampaignSlug = flagMetadataCampaignSlug;
        this.flagMetadataCampaignType = flagMetadataCampaignType;
        this.hitContent = hitContent;
        this.ds = SDK_APP;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, complexity
    toApiKeys() {
        const apiKeys = {
            [VISITOR_ID_API_ITEM]: this.visitorId,
            [DS_API_ITEM]: this.ds,
            [CUSTOMER_ENV_ID_API_ITEM]: `${this.config?.envId}`,
            [T_API_ITEM]: this.type,
            cv: {}
        };
        const customVariable = {
            version: `${this.version}`,
            logLevel: `${LogLevel[this.logLevel]}`,
            timestamp: `${this.timestamp}`,
            timeZone: `${this.timeZone}`,
            label: `${this.label}`,
            'stack.type': `${this.stackType}`,
            'stack.name': `${this.stackName}`,
            'stack.version': `${this.stackVersion}`
        };
        if (this.lastBucketingTimestamp !== undefined) {
            customVariable.lastBucketingTimestamp = `${this.lastBucketingTimestamp}`;
        }
        if (this.lastInitializationTimestamp !== undefined) {
            customVariable.lastInitializationTimestamp = `${this.lastInitializationTimestamp}`;
        }
        if (this.flagshipInstanceId !== undefined) {
            customVariable.flagshipInstanceId = `${this.flagshipInstanceId}`;
        }
        if (this.accountId) {
            customVariable.accountId = `${this.accountId}`;
        }
        if (this.envId) {
            customVariable.envId = `${this.envId}`;
        }
        if (this.sdkBucketingFile !== undefined) {
            customVariable.sdkBucketingFile = JSON.stringify(this.sdkBucketingFile);
        }
        if (this.stackOriginName !== undefined) {
            customVariable['stack.origin.name'] = `${this.stackOriginName}`;
        }
        if (this.stackOriginVersion !== undefined) {
            customVariable['stack.origin.version'] = `${this.stackOriginVersion}`;
        }
        if (this.sdkStatus !== undefined) {
            customVariable['sdk.status'] = `${FlagshipStatus[this.sdkStatus]}`;
        }
        if (this.sdkConfigMode !== undefined) {
            customVariable['sdk.config.mode'] = `${this.sdkConfigMode}`;
        }
        if (this.sdkConfigCustomLogManager !== undefined) {
            customVariable['sdk.config.customLogManager'] = `${this.sdkConfigCustomLogManager}`;
        }
        if (this.sdkConfigCustomCacheManager !== undefined) {
            customVariable['sdk.config.customCacheManager'] = `${this.sdkConfigCustomCacheManager}`;
        }
        if (this.sdkConfigStatusListener !== undefined) {
            customVariable['sdk.config.custom.StatusListener'] = `${this.sdkConfigStatusListener}`;
        }
        if (this.sdkConfigTimeout !== undefined) {
            customVariable['sdk.config.timeout'] = `${this.sdkConfigTimeout}`;
        }
        if (this.sdkConfigPollingInterval !== undefined) {
            customVariable['sdk.config.pollingTime'] = `${this.sdkConfigPollingInterval}`;
        }
        if (this.sdkConfigTrackingManagerConfigStrategy !== undefined) {
            customVariable['sdk.config.trackingManager.strategy'] = `${CacheStrategy[this.sdkConfigTrackingManagerConfigStrategy]}`;
        }
        if (this.sdkConfigTrackingManagerConfigBatchIntervals !== undefined) {
            customVariable['sdk.config.trackingManager.batchIntervals'] = `${this.sdkConfigTrackingManagerConfigBatchIntervals}`;
        }
        if (this.sdkConfigTrackingManagerConfigPoolMaxSize !== undefined) {
            customVariable['sdk.config.trackingManager.poolMaxSize'] = `${this.sdkConfigTrackingManagerConfigPoolMaxSize}`;
        }
        if (this.sdkConfigFetchNow !== undefined) {
            customVariable['sdk.config.fetchNow'] = `${this.sdkConfigFetchNow}`;
        }
        if (this.sdkConfigEnableClientCache !== undefined) {
            customVariable['sdk.config.enableClientCache'] = `${this.sdkConfigEnableClientCache}`;
        }
        if (this.sdkConfigInitialBucketing !== undefined) {
            customVariable['sdk.config.initialBucketing'] = JSON.stringify(this.sdkConfigInitialBucketing);
        }
        if (this.sdkConfigDecisionApiUrl !== undefined) {
            customVariable['sdk.config.decisionApiUrl'] = `${this.sdkConfigDecisionApiUrl}`;
        }
        if (this.sdkConfigHitDeduplicationTime !== undefined) {
            customVariable['sdk.config.hitDeduplicationTime'] = `${this.sdkConfigHitDeduplicationTime}`;
        }
        if (this.sdkConfigUsingCustomHitCache !== undefined) {
            customVariable['sdk.config.usingCustomHitCache'] = JSON.stringify(this.sdkConfigUsingCustomHitCache);
        }
        if (this.sdkConfigUsingCustomVisitorCache !== undefined) {
            customVariable['sdk.config.usingCustomVisitorCache'] = JSON.stringify(this.sdkConfigUsingCustomVisitorCache);
        }
        if (this.sdkConfigUsingOnVisitorExposed !== undefined) {
            customVariable['sdk.config.usingOnVisitorExposed'] = JSON.stringify(this.sdkConfigUsingOnVisitorExposed);
        }
        if (this.sdkConfigFetchThirdPartyData !== undefined) {
            customVariable['sdk.config.fetchThirdPartyData'] = JSON.stringify(this.sdkConfigFetchThirdPartyData);
        }
        if (this.sdkConfigFetchFlagsBufferingTime !== undefined) {
            customVariable['sdk.config.fetchFlagsBufferingTime'] = JSON.stringify(this.sdkConfigFetchFlagsBufferingTime);
        }
        if (this.sdkConfigNextFetchConfig !== undefined) {
            customVariable['sdk.config.nextFetchConfig'] = JSON.stringify(this.sdkConfigNextFetchConfig);
        }
        if (this.sdkConfigDisableDeveloperUsageTracking !== undefined) {
            customVariable['sdk.config.disableDeveloperUsageTracking'] = JSON.stringify(this.sdkConfigDisableDeveloperUsageTracking);
        }
        if (this.sdkConfigDisableCache !== undefined) {
            customVariable['sdk.config.disableCache'] = JSON.stringify(this.sdkConfigDisableCache);
        }
        if (this.httpRequestUrl !== undefined) {
            customVariable['http.request.url'] = `${this.httpRequestUrl}`;
        }
        if (this.httpRequestMethod !== undefined) {
            customVariable['http.request.method'] = `${this.httpRequestMethod}`;
        }
        if (this.httpRequestHeaders !== undefined) {
            customVariable['http.request.headers'] = JSON.stringify(this.httpRequestHeaders);
        }
        if (this.httpRequestBody !== undefined) {
            customVariable['http.request.body'] = JSON.stringify(this.httpRequestBody);
        }
        if (this.httpResponseUrl !== undefined) {
            customVariable['http.response.url'] = `${this.httpResponseUrl}`;
        }
        if (this.httpResponseMethod !== undefined) {
            customVariable['http.response.method'] = `${this.httpResponseMethod}`;
        }
        if (this.httpResponseHeaders !== undefined) {
            customVariable['http.response.headers'] = JSON.stringify(this.httpResponseHeaders);
        }
        if (this.httpResponseCode !== undefined) {
            customVariable['http.response.code'] = `${this.httpResponseCode}`;
        }
        if (this.httpResponseBody !== undefined) {
            customVariable['http.response.body'] = JSON.stringify(this.httpResponseBody);
        }
        if (this.httpResponseTime !== undefined) {
            customVariable['http.response.time'] = `${this.httpResponseTime}`;
        }
        if (this.visitorId !== undefined) {
            customVariable['visitor.visitorId'] = `${this.visitorId}`;
        }
        if (this.anonymousId !== undefined) {
            customVariable['visitor.anonymousId'] = `${this.anonymousId}`;
        }
        if (this.visitorSessionId !== undefined) {
            customVariable['visitor.sessionId'] = `${this.visitorSessionId}`;
        }
        if (this.visitorStatus !== undefined) {
            customVariable['visitor.status'] = `${this.visitorStatus}`;
        }
        if (this.visitorInstanceType !== undefined) {
            customVariable['visitor.instanceType'] = `${this.visitorInstanceType}`;
        }
        if (this.visitorContext !== undefined) {
            for (const key in this.visitorContext) {
                const element = this.visitorContext[key];
                customVariable[`visitor.context.${key}`] = `${element}`;
            }
        }
        if (this.visitorConsent !== undefined) {
            customVariable['visitor.consent'] = `${this.visitorConsent}`;
        }
        if (this.visitorAssignmentHistory !== undefined) {
            for (const key in this.visitorAssignmentHistory) {
                const element = this.visitorAssignmentHistory[key];
                customVariable[`visitor.assignments.[${key}]`] = element;
            }
        }
        if (this.visitorFlags !== undefined) {
            this.visitorFlags.forEach((item, flagKey) => {
                for (const itemKey in item) {
                    const itemValue = item[itemKey];
                    const hasMetadataKey = itemKey === 'value' || itemKey === 'key';
                    const value = typeof itemValue === 'string' ? itemValue : JSON.stringify(itemValue);
                    customVariable[`visitor.flags.[${flagKey}]${hasMetadataKey ? '' : '.metadata'}.${itemKey}`] = value;
                }
            });
        }
        if (this.visitorIsAuthenticated !== undefined) {
            customVariable['visitor.isAuthenticated'] = `${this.visitorIsAuthenticated}`;
        }
        if (this.visitorInitialCampaigns !== undefined) {
            customVariable['visitor.initialCampaigns'] = JSON.stringify(this.visitorInitialCampaigns);
        }
        if (this.visitorInitialFlagsData !== undefined) {
            customVariable['visitor.initialFlagsData'] = JSON.stringify(Array.isArray(this.visitorInitialFlagsData) ? this.visitorInitialFlagsData : Array.from(this.visitorInitialFlagsData));
        }
        if (this.visitorCampaigns !== undefined) {
            customVariable['visitor.campaigns'] = JSON.stringify(this.visitorCampaigns);
        }
        if (this.visitorCampaignFromCache !== undefined) {
            customVariable['visitor.campaignFromCache'] = JSON.stringify(this.visitorCampaignFromCache);
        }
        if (this.contextKey !== undefined) {
            customVariable.contextKey = `${this.contextKey}`;
        }
        if (this.contextValue !== undefined) {
            customVariable.contextValue = `${this.contextValue}`;
        }
        if (this.flagKey !== undefined) {
            customVariable['flag.key'] = `${this.flagKey}`;
        }
        if (this.flagValue !== undefined) {
            customVariable['flag.value'] = `${this.flagValue}`;
        }
        if (this.flagDefault !== undefined) {
            customVariable['flag.default'] = JSON.stringify(this.flagDefault);
        }
        if (this.flagMetadataCampaignId !== undefined) {
            customVariable['flag.metadata.campaignId'] = `${this.flagMetadataCampaignId}`;
        }
        if (this.flagMetadataCampaignName !== undefined) {
            customVariable['flag.metadata.campaignName'] = `${this.flagMetadataCampaignName}`;
        }
        if (this.flagMetadataVariationGroupId !== undefined) {
            customVariable['flag.metadata.variationGroupId'] = `${this.flagMetadataVariationGroupId}`;
        }
        if (this.flagMetadataVariationGroupName !== undefined) {
            customVariable['flag.metadata.variationGroupName'] = `${this.flagMetadataVariationGroupName}`;
        }
        if (this.flagMetadataVariationId !== undefined) {
            customVariable['flag.metadata.variationId'] = `${this.flagMetadataVariationId}`;
        }
        if (this.flagMetadataVariationName !== undefined) {
            customVariable['flag.metadata.variationName'] = `${this.flagMetadataVariationName}`;
        }
        if (this.flagMetadataCampaignSlug !== undefined) {
            customVariable['flag.metadata.campaignSlug'] = `${this.flagMetadataCampaignSlug}`;
        }
        if (this.flagMetadataCampaignType !== undefined) {
            customVariable['flag.metadata.campaignType'] = `${this.flagMetadataCampaignType}`;
        }
        if (this.flagMetadataCampaignIsReference !== undefined) {
            customVariable['flag.metadata.isReference'] = `${this.flagMetadataCampaignIsReference}`;
        }
        if (this.hitContent !== undefined) {
            for (const key in this.hitContent) {
                const element = this.hitContent[key];
                customVariable[`hit.${key}`] = typeof element === 'string' ? element : JSON.stringify(element);
            }
        }
        if (this.batchTriggeredBy !== undefined) {
            customVariable.batchTriggeredBy = `${BatchTriggeredBy[this.batchTriggeredBy]}`;
        }
        apiKeys.cv = customVariable;
        return apiKeys;
    }
    isReady(checkParent = true) {
        return !!((!checkParent || super.isReady()));
    }
    getErrorMessage() {
        return ERROR_MESSAGE;
    }
}
