export const FS_HIT_PREFIX = 'FS_DEFAULT_HIT_CACHE';
export class DefaultHitCache {
    cacheHit(hits) {
        const localDatabaseJson = localStorage.getItem(FS_HIT_PREFIX) || '{}';
        const localDatabase = JSON.parse(localDatabaseJson);
        const newLocalDatabase = {
            ...localDatabase,
            ...hits
        };
        localStorage.setItem(FS_HIT_PREFIX, JSON.stringify(newLocalDatabase));
        return Promise.resolve();
    }
    lookupHits() {
        const localDatabaseJson = localStorage.getItem(FS_HIT_PREFIX) || '{}';
        const localDatabase = JSON.parse(localDatabaseJson);
        return Promise.resolve(localDatabase);
    }
    flushHits(hitKeys) {
        const localDatabaseJson = localStorage.getItem(FS_HIT_PREFIX) || '{}';
        const localDatabase = JSON.parse(localDatabaseJson);
        hitKeys.forEach(key => {
            delete localDatabase[key];
        });
        localStorage.setItem(FS_HIT_PREFIX, JSON.stringify(localDatabase));
        return Promise.resolve();
    }
    flushAllHits() {
        localStorage.removeItem(FS_HIT_PREFIX);
        return Promise.resolve();
    }
}
