export class HttpError extends Error {
    _statusCode;
    _headers;
    get headers() {
        return this._headers;
    }
    get statusCode() {
        return this._statusCode;
    }
    constructor(statusCode, message, headers) {
        super(message);
        this._statusCode = statusCode;
        this._headers = headers;
    }
}
