import { BATCH_LOOP_STARTED, BATCH_LOOP_STOPPED, DEFAULT_HIT_CACHE_TIME_MS, HitType, HIT_CACHE_ERROR, HIT_CACHE_LOADED, PROCESS_CACHE, PROCESS_LOOKUP_HIT, TRACKING_MANAGER } from '../enum/index';
import { CacheStrategy } from '../enum/CacheStrategy';
import { Transaction, Event, Item, Page, Screen } from '../hit/index';
import { Segment } from '../hit/Segment';
import { logDebugSprintf, logError, logErrorSprintf, logInfo, logInfoSprintf } from '../utils/utils';
import { BatchingContinuousCachingStrategy } from './BatchingContinuousCachingStrategy';
import { BatchingPeriodicCachingStrategy } from './BatchingPeriodicCachingStrategy';
import { NoBatchingContinuousCachingStrategy } from './NoBatchingContinuousCachingStrategy';
import { Activate } from '../hit/Activate';
import { BatchTriggeredBy } from '../enum/BatchTriggeredBy';
export const LOOKUP_HITS_JSON_ERROR = 'JSON DATA must be an array of object';
export const LOOKUP_HITS_JSON_OBJECT_ERROR = 'JSON DATA must fit the type HitCacheDTO';
export class TrackingManagerAbstract {
    _httpClient;
    _config;
    _hitsPoolQueue;
    _activatePoolQueue;
    _troubleshootingQueue;
    _analyticHitQueue;
    strategy;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _intervalID;
    _isPooling = false;
    _flagshipInstanceId;
    get flagshipInstanceId() {
        return this._flagshipInstanceId;
    }
    get troubleshootingData() {
        return this.strategy.troubleshootingData;
    }
    set troubleshootingData(v) {
        this.strategy.troubleshootingData = v;
    }
    constructor(httpClient, config, flagshipInstanceId) {
        this._flagshipInstanceId = flagshipInstanceId;
        this._hitsPoolQueue = new Map();
        this._activatePoolQueue = new Map();
        this._troubleshootingQueue = new Map();
        this._analyticHitQueue = new Map();
        this._httpClient = httpClient;
        this._config = config;
        this.strategy = this.initStrategy();
        this.lookupHits();
    }
    initStrategy() {
        let strategy;
        const argument = {
            config: this.config,
            httpClient: this.httpClient,
            hitsPoolQueue: this._hitsPoolQueue,
            activatePoolQueue: this._activatePoolQueue,
            troubleshootingQueue: this._troubleshootingQueue,
            analyticHitQueue: this._analyticHitQueue,
            flagshipInstanceId: this.flagshipInstanceId
        };
        switch (this.config.trackingManagerConfig?.cacheStrategy) {
            case CacheStrategy.PERIODIC_CACHING:
                strategy = new BatchingPeriodicCachingStrategy(argument);
                break;
            case CacheStrategy.CONTINUOUS_CACHING:
                strategy = new BatchingContinuousCachingStrategy(argument);
                break;
            default:
                strategy = new NoBatchingContinuousCachingStrategy(argument);
                break;
        }
        return strategy;
    }
    get httpClient() {
        return this._httpClient;
    }
    get config() {
        return this._config;
    }
    async sendTroubleshootingHit(hit) {
        await this.strategy.sendTroubleshootingHit(hit);
    }
    startBatchingLoop() {
        const timeInterval = (this.config.trackingManagerConfig?.batchIntervals) * 1000;
        logInfoSprintf(this.config, TRACKING_MANAGER, BATCH_LOOP_STARTED, timeInterval);
        this._intervalID = setInterval(() => {
            this.batchingLoop();
        }, timeInterval);
    }
    stopBatchingLoop() {
        clearInterval(this._intervalID);
        this._isPooling = false;
        logInfo(this.config, BATCH_LOOP_STOPPED, TRACKING_MANAGER);
    }
    async batchingLoop() {
        if (this._isPooling) {
            return;
        }
        this._isPooling = true;
        await this.strategy.sendBatch(BatchTriggeredBy.Timer);
        await this.strategy.sendTroubleshootingQueue();
        await this.strategy.sendUsageHitQueue();
        this._isPooling = false;
    }
    checkLookupHitData(item) {
        if (item?.version === 1 && item?.data?.type && item?.data?.content) {
            return true;
        }
        logError(this.config, LOOKUP_HITS_JSON_OBJECT_ERROR, PROCESS_LOOKUP_HIT);
        return false;
    }
    async lookupHits() {
        try {
            const hitCacheImplementation = this.config.hitCacheImplementation;
            if (this.config.disableCache || !hitCacheImplementation || typeof hitCacheImplementation.lookupHits !== 'function') {
                return;
            }
            const hitsCache = await hitCacheImplementation.lookupHits();
            logDebugSprintf(this.config, PROCESS_CACHE, HIT_CACHE_LOADED, hitsCache);
            if (!hitsCache || !Object.keys(hitsCache).length) {
                return;
            }
            const checkHitTime = (time) => (((Date.now() - time)) <= DEFAULT_HIT_CACHE_TIME_MS);
            const wrongHitKeys = [];
            Object.entries(hitsCache).forEach(([key, item]) => {
                if (!this.checkLookupHitData(item) || !checkHitTime(item.data.time)) {
                    wrongHitKeys.push(key);
                    return;
                }
                let hit;
                switch (item.data.type) {
                    case HitType.EVENT:
                        hit = new Event(item.data.content);
                        break;
                    case HitType.ITEM:
                        hit = new Item(item.data.content);
                        break;
                    case HitType.PAGE:
                        hit = new Page(item.data.content);
                        break;
                    case HitType.SCREEN:
                        hit = new Screen(item.data.content);
                        break;
                    case 'SEGMENT':
                        hit = new Segment(item.data.content);
                        break;
                    case 'ACTIVATE':
                        hit = new Activate(item.data.content);
                        hit.key = key;
                        hit.createdAt = item.data.content.createdAt;
                        hit.config = this.config;
                        this._activatePoolQueue.set(key, hit);
                        return;
                    case HitType.TRANSACTION:
                        hit = new Transaction(item.data.content);
                        break;
                    default:
                        return;
                }
                hit.key = key;
                hit.createdAt = item.data.content.createdAt;
                hit.config = this.config;
                this._hitsPoolQueue.set(key, hit);
            });
            if (wrongHitKeys.length) {
                await this.strategy.flushHits(wrongHitKeys);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }
        catch (error) {
            logErrorSprintf(this.config, PROCESS_CACHE, HIT_CACHE_ERROR, 'lookupHits', error.message || error);
        }
    }
    async sendUsageHit(hit) {
        await this.strategy.sendUsageHit(hit);
    }
    async addTroubleshootingHit(hit) {
        await this.strategy.addTroubleshootingHit(hit);
    }
}
