import { FlagshipStatus, FLAG_USER_EXPOSED, METHOD_DEACTIVATED_ERROR, FLAG_METADATA, METADATA_SDK_NOT_READY } from '../enum/index';
import { logErrorSprintf } from '../utils/utils';
import { DefaultStrategy } from './DefaultStrategy';
import { FlagMetadata } from '../flag/FlagMetadata';
export class NotReadyStrategy extends DefaultStrategy {
    async synchronizeModifications() {
        this.log('synchronizeModifications');
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getModificationSync(params) {
        this.log('getModification');
        return params.defaultValue;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getModificationsSync(params, _activateAll) {
        this.log('getModifications');
        const flags = {};
        params.forEach(item => {
            flags[item.key] = item.defaultValue;
        });
        return flags;
    }
    async lookupHits() {
        //
    }
    async lookupVisitor() {
        //
    }
    async cacheVisitor() {
        //
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getModificationInfoSync(_key) {
        this.log('getModificationInfo');
        return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async activateModification(_params) {
        this.log('activateModification');
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async activateModifications(_params) {
        this.log('activateModifications');
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendHit(_hit) {
        this.log('sendHit');
        return Promise.resolve();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendHits(_hits) {
        this.log('sendHits');
        return Promise.resolve();
    }
    async fetchFlags() {
        this.log('fetchFlags');
    }
    getFlagValue(param) {
        this.log('Flag.value');
        return param.defaultValue;
    }
    async visitorExposed() {
        this.log(FLAG_USER_EXPOSED);
    }
    getFlagMetadata(param) {
        const emptyMetaData = FlagMetadata.Empty();
        logErrorSprintf(this.config, FLAG_METADATA, METADATA_SDK_NOT_READY, this.visitor.visitorId, param.key, emptyMetaData);
        return emptyMetaData;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendTroubleshootingHit(_hit) {
        return Promise.resolve();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async sendSdkConfigAnalyticHit() {
        return Promise.resolve();
    }
    log(methodName) {
        logErrorSprintf(this.config, methodName, METHOD_DEACTIVATED_ERROR, this.visitor.visitorId, methodName, FlagshipStatus[FlagshipStatus.NOT_INITIALIZED]);
    }
}
