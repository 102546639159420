import { S_API_ITEM } from '../enum/index';
import { HitAbstract } from './HitAbstract';
export const ERROR_MESSAGE = 'data property is required';
export class Segment extends HitAbstract {
    _context;
    get context() {
        return this._context;
    }
    set context(v) {
        this._context = v;
    }
    constructor(param) {
        super({
            type: 'SEGMENT',
            userIp: param.userIp,
            screenResolution: param.screenResolution,
            locale: param.locale,
            sessionNumber: param.sessionNumber,
            visitorId: param.visitorId,
            anonymousId: param.anonymousId
        });
        this.context = param.context;
    }
    isReady(checkParent = true) {
        return !!((!checkParent || super.isReady()) && this.context);
    }
    toApiKeys() {
        const apiKeys = super.toApiKeys();
        apiKeys[S_API_ITEM] = this.context;
        return apiKeys;
    }
    toObject() {
        return {
            ...super.toObject(),
            context: this.context
        };
    }
    getErrorMessage() {
        return ERROR_MESSAGE;
    }
}
